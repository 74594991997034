import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Layout, Typography, Card, Flex, Row, Col, Form, Input, Switch, Button, App } from 'antd';
const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
import { Logo } from '../../components/Logo/Logo';
import { loginUser } from '../../slices/actions/user.actions';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const error = useSelector((state) => state.user.error);
  const currentYear = new Date().getFullYear();

  const { notification } = App.useApp();
  const dispatch = useDispatch();


  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  const handleRememberChange = (checked) => {
    setRemember(checked);
  };

  const handleSubmit = () => {
    dispatch(loginUser({ email, password, remember }));
  };

  useEffect(() => {
    if (error) {
      notification.error({ message: error });
    }
  }, [error, notification]);

  return (
    <Layout>
      <Helmet>
        <title>Вхід в систему</title>
      </Helmet>
      <Header>
        <Flex align="center" gap={8} style={{ height: '100%' }}>
          <Logo style={{ fontSize: 32 }} />
          <Title level={5} style={{ marginBottom: 0 }}>Magnum CRM</Title>
        </Flex>
      </Header>
      <Content style={{ paddingTop: 24 }}>
        <Row>
          <Col span={10} offset={7}>
            <Title level={2}>Вхід в систему</Title>
            <Paragraph>Використайте логін та пароль, які отримали від адміністратора сайту</Paragraph>
            <Card title="Логін" bordered="false">
              <Form
                name="login"
                layout="vertical"
                onFinish={handleSubmit}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  onChange={handleEmailChange}
                  required
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  label="Пароль"
                  name="password"
                  onChange={handlePasswordChange}
                  required
                >
                  <Input.Password placeholder="Пароль" />
                </Form.Item>
                <Form.Item
                  name="remember"
                  label="Запам'ятати мене"
                  valuePropName="checked"
                >
                  <Switch defaultChecked onChange={handleRememberChange} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Логін</Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Flex justify="center">Copyright © {currentYear}. Magnum CRM</Flex>
      </Footer>
    </Layout>
  );
};
