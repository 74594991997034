import { createSlice } from '@reduxjs/toolkit';
import { DataKey, SortOrderReduced } from '../../const.js';
import { fetchLeads, fetchOneLead } from '../actions/leads.actions.js';
import { convertFetchMetaToFrontend, EntitiesAdapter } from '../../adapter.js';

const initialState = {
  entities: [],
  current: null,
  ids: [],
  ui: {
    [DataKey.OrderColumn]: DataKey.CreatedAt,
    [DataKey.OrderDirection]: SortOrderReduced.Descend,
    [DataKey.PerPage]: 10,
    [DataKey.CurrentPage]: 1,
    [DataKey.Search]: '',
    [DataKey.Total]: 0,
    [DataKey.IsLoading]: false,
  },
};

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    clearCurrentLead(state) {
      state.current = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.ui = {
          ...state.ui,
          [DataKey.IsLoading]: true,
        };
      })
      .addCase(fetchLeads.fulfilled, (state, { payload }) => {
        const { data, meta } = payload;
        state.entities = data.map(EntitiesAdapter.leadToFrontend);
        state.ids = data.map((item) => item[DataKey.Id]);
        state.ui = {
          ...state.ui,
          ...convertFetchMetaToFrontend(meta),
          [DataKey.IsLoading]: false,
        };
      })
      .addCase(fetchLeads.rejected, (state) => {
        state.ui = {
          ...state.ui,
          [DataKey.IsLoading]: false,
        };

      })
      .addCase(fetchOneLead.fulfilled, (state, { payload }) => {
        state.current = EntitiesAdapter.leadToFrontend(payload);
      });
  },
});

export const { clearCurrentLead } = leadsSlice.actions;

export default leadsSlice.reducer;
