import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CarForm } from '../../components/CarForm/CarForm';
import { AppRoute, DataKey } from '../../const';
import { fetchOneCar, updateCar } from '../../slices/actions/cars.actions';

export const CarsEdit = () => {
  const { loading } = useSelector((state) => state.form);
  const currentCar = useSelector((state) => state.cars.currentEntity);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { message } = App.useApp();

  const getAdaptedCard = ({ year, buyAt, ...rest }) => {
    const car = { ...rest };

    if (year) {
      car.year = dayjs(car.year);
    }

    if (buyAt) {
      car.buyAt = dayjs(car.buyAt);
    }

    return car;
  };

  const adaptedCar = getAdaptedCard(currentCar);

  const handleFormSubmit = async (values) => {
    const adaptedValues = {
      ...values,
      [DataKey.Year]: values[DataKey.Year].year(),
      [DataKey.BuyAt]: values[DataKey.BuyAt].format('YYYY-MM-DD'),
    };

    await dispatch(updateCar({ id, data: adaptedValues }))
      .unwrap()
      .then(() => {
        message.success('Авто успішно оновлено!');
      });
    dispatch(fetchOneCar(id));
  };

  useEffect(() => {
    dispatch(fetchOneCar(id));
  }, [dispatch, id]);

  return (
    <div>
      <Helmet>
        <title>Редагувати авто</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Cars}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <CarForm
              title="Редагувати авто"
              initialValues={adaptedCar}
              loading={loading}
              onFinish={handleFormSubmit}
            />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
