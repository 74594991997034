import { createSlice } from '@reduxjs/toolkit';
import { createClient, fetchOneClient, updateClient } from '../actions/clients.actions';
import { fetchOneLead } from '../actions/leads.actions';
import { EntitiesAdapter } from '../../adapter';

const initialState = {
  loading: false,
  errors: {},
  lead: null,
  client: null,
};

export const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    clearLeadData(state) {
      state.lead = null;
      state.client = null;
      state.errors = {};
    },
    clearErrors(state) {
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneLead.fulfilled, (state, { payload }) => {
        state.lead = EntitiesAdapter.leadToFrontend(payload);
      })
      .addCase(fetchOneClient.fulfilled, (state, { payload }) => {
        state.client = payload;
      })
      .addCase(updateClient.fulfilled, (state, { payload }) => {
        state.client = EntitiesAdapter.clientToFrontend(payload.data);
      })
      .addCase(createClient.fulfilled, (state, { payload }) => {
        state.client = EntitiesAdapter.clientToFrontend(payload.data);
      });

    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.loading = true;
        state.errors = {};
      }
    ).addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state) => {
        state.loading = false;
        state.errors = {};
      }
    ).addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      }
    );
  },
});

export const { clearLeadData, clearErrors } = leadSlice.actions;

export default leadSlice.reducer;
