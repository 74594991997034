import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ClientForm } from '../../components/ClientForm/ClientForm';
import { AppRoute } from '../../const';
import { createClient } from '../../slices/actions/clients.actions';

export const ClientsCreate = () => {
  const { modal } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    await dispatch(createClient(values)).unwrap().then(() => {
      modal.success({
        title: 'Успіх',
        content: 'Клієнт успішно створений!',
        onOk: () => navigate(AppRoute.Clients),
      });
    });
  };

  return (
    <div>
      <Helmet>
        <title>Додати нового клієнта</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Clients}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <ClientForm title="Створити клієнта" onFinish={handleFormSubmit} />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
