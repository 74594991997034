export const theme = {
  token: {
    colorLink: '#e6a60e',
    colorPrimary: '#e6a60e',
    colorIcon: '#e6a60e',
  },
  components: {
    Layout: {
      headerBg: '#fff'
    },
    Menu: {
      activeBarBorderWidth: 1,
      iconSize: 24,
      collapsedIconSize: 24,
      itemHeight: 48,
    }
  },
};
