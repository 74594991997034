import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { LeadInfoHeader } from './Components/LeadInfoHeader/LeadInfoHeader';
import { ClientForm } from './Components/ClientForm/ClientForm';
import { LeadDetails } from './Components/LeadDetails/LeadDetails';
import { AppRoute } from '../../const';
import { fetchOneLead } from '../../slices/actions/leads.actions';
import { clearLeadData } from '../../slices/slices/lead.slice';

function LeadsEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearLeadData());
    dispatch(fetchOneLead(id));
    return () => {
      dispatch(clearLeadData());
    };
  }, [dispatch, id]);

  return (
    <>
      <Helmet>
        <title>Редагувати заявку</title>
      </Helmet>
      <Link to={AppRoute.Leads}>
        <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
      </Link>
      <Card title="Редагувати заявку">
        <Row gutter={32}>
          <Col span={8}>
            <LeadInfoHeader />
            <ClientForm />
          </Col>
          <Col span={16}>
            <LeadDetails />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export { LeadsEdit };
