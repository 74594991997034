import axios from 'axios';
import { Token } from './token';

const BACKEND_URL = 'https://magnum-cars.stages-dev.site/';
// const BACKEND_URL = 'http://127.0.0.1/';
const REQUEST_TIMEOUT = 5000;

export const createApi = () => {
  const api = axios.create({
    baseURL: BACKEND_URL,
    timeout: REQUEST_TIMEOUT,
  });

  api.interceptors.request.use(
    (config) => {
      const token = Token.get();

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error),
  );

  return api;
};
