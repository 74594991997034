import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertFetchParamsForBackend, EntitiesAdapter } from '../../adapter.js';
import { Endpoint } from '../../const.js';

const ENDPOINT = '/api/statuses';

export const fetchStatusesWithItemsCount = createAsyncThunk(
  'statusesWithItemsCount/fetch',
  async (_, { extra }) => {
    const { api } = extra;
    const response = await api.get(Endpoint.LeadsStatuses);
    return response.data;
  },
);

export const fetchStatuses = createAsyncThunk(
  'statuses/fetch',
  async (params = {}, { extra }) => {
    const { api } = extra;
    const mainResponse = await api.get(ENDPOINT, {
      params: convertFetchParamsForBackend(params),
    });
    const secondaryResponse = await api.get(Endpoint.LeadsStatuses);
    return [mainResponse.data, secondaryResponse.data];
  },
);

export const fetchOneStatus = createAsyncThunk(
  'statuses/fetch/one',
  async (id, { extra }) => {
    const { api } = extra;
    const response = await api.get(`${ENDPOINT}/${id}`);
    const { data } = response.data;
    return EntitiesAdapter.statusToFrontend(data);
  },
);

export const createStatus = createAsyncThunk(
  'statuses/create',
  async (data = {}, { extra, rejectWithValue }) => {
    const { api } = extra;
    try {
      const response = await api.post(ENDPOINT, EntitiesAdapter.statusToBackend(data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const updateStatus = createAsyncThunk(
  'statuses/update',
  async (payload, { extra, dispatch, getState, rejectWithValue }) => {
    const { id, data } = payload;
    const { api } = extra;
    try {
      const response = await api.patch(`${ENDPOINT}/${id}`, EntitiesAdapter.statusToBackend(data));
      const state = getState();
      dispatch(fetchStatuses(state.statuses.ui));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const deleteStatus = createAsyncThunk(
  'statuses/delete',
  async (id, { getState, dispatch, extra }) => {
    const { api } = extra;
    const response = await api.delete(`${ENDPOINT}/${id}`);
    const state = getState();
    dispatch(fetchStatuses(state.statuses.ui));
    return response.data;
  },
);
