export class Token {
  static _name = 'magnum-car-crm';

  static get () {
    const token = localStorage.getItem(this._name);
    return token ?? '';
  }

  static save(token) {
    localStorage.setItem(this._name, token);
  }

  static drop() {
    localStorage.removeItem(this._name);
  }
}
