import { faker } from '@faker-js/faker';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { App, Button, Flex, Form, Input, Modal, Typography } from 'antd';
import { CloseOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DataKey, DataLabel } from '../../../../const';
import { clearErrors } from '../../../../slices/slices/form.slice';
import { createClient, updateClient } from '../../../../slices/actions/clients.actions';

const { Text } = Typography;

export const ClientModal = ({ client, visible, onClose }) => {
  const { errors } = useSelector((state) => state.lead);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const loading = false;

  const getValidationStatus = (field) => errors[field] ? 'error' : 'success';
  const getValidationErrors = (field) => errors[field]?.join('\n');

  const handleModalClose = () => {
    form.resetFields();
    onClose();
  };

  const handleModalOk = () => {
    form.submit();
  };

  const handleFormSubmit = async (values) => {
    if (client) {
      await dispatch(updateClient({ id: client.id, data: values }))
        .unwrap()
        .then(() => {
          handleModalClose();
          message.success('Клієнт успішно оновлений!');
        });
    } else {
      await dispatch(createClient(values))
        .unwrap()
        .then(() => {
          handleModalClose();
          message.success('Клієнт успішно створений!');
        });
    }
  };

  useEffect(() => {
    if (client) {
      form.setFieldsValue(client);
    } else {
      form.setFieldsValue({
        firstName: faker.person.firstName(),
        phone: faker.phone.number(),
        ipnCode: faker.phone.number(),
        passCode: faker.phone.number(),
      });
    }
  }, [form, client]);

  useEffect(() => () => {
    dispatch(clearErrors());
  }, [dispatch, form]);

  return (
    <Modal
      title={client === 'edit' ? 'Редагувати клієнта' : 'Створити нового клієнта'}
      okText={client === 'edit' ? 'Редагувати' : 'Створити'}
      cancelText="Cкасувати"
      open={visible}
      onOk={handleModalOk}
      onCancel={handleModalClose}
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <Form.Item
          label={<Text strong>{DataLabel.LastName}</Text>}
          name={DataKey.LastName}
          validateStatus={getValidationStatus(DataKey.LastName)}
          help={getValidationErrors(DataKey.LastName)}
        >
          {/* suffix={loading && <LoadingOutlined />} */}
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.FirstName}</Text>}
          name={DataKey.FirstName}
          validateStatus={getValidationStatus(DataKey.FirstName)}
          help={getValidationErrors(DataKey.FirstName)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.MiddleName}</Text>}
          name={DataKey.MiddleName}
          validateStatus={getValidationStatus(DataKey.MiddleName)}
          help={getValidationErrors(DataKey.MiddleName)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.Email}</Text>}
          name={DataKey.Email}
          validateStatus={getValidationStatus(DataKey.Email)}
          help={getValidationErrors(DataKey.Email)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.Phone}</Text>}
          name={DataKey.Phone}
          validateStatus={getValidationStatus(DataKey.Phone)}
          help={getValidationErrors(DataKey.Phone)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.IpnCode}</Text>}
          name={DataKey.IpnCode}
          validateStatus={getValidationStatus(DataKey.IpnCode)}
          help={getValidationErrors(DataKey.IpnCode)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.PassCode}</Text>}
          name={DataKey.PassCode}
          validateStatus={getValidationStatus(DataKey.PassCode)}
          help={getValidationErrors(DataKey.PassCode)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Form.Item
            label={<Text strong>{DataLabel.Comment}</Text>}
            name={DataKey.Comment}
            validateStatus={getValidationStatus(DataKey.Comment)}
            help={getValidationErrors(DataKey.Comment)}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
          {
            loading &&
            <LoadingOutlined
              style={{ position: 'absolute', top: 40, right: 11, color: 'rgba(0, 0, 0, 0.25)' }}
            />
          }
        </div>
        <Form.Item
          label={<Text strong>Додаткова інформація</Text>}
        >
          <Form.List name={DataKey.AdditionalFields}>
            {(fields, operations) => (
              <Flex vertical>
                {fields.map((field) => (
                  <Flex
                    key={field.key}
                    gap={16}
                    align="center"
                  >
                    <Form.Item
                      key={field.key}
                      name={[field.name, 'name']}
                      label="Назва"
                      validateStatus={getValidationStatus(`additional_fields.${field.key}.name`)}
                      help={getValidationErrors(`additional_fields.${field.key}.name`)}
                    >
                      <Input placeholder="Поле" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'value']}
                      label="Значення"
                      validateStatus={getValidationStatus(`additional_fields.${field.key}.value`)}
                      help={getValidationErrors(`additional_fields.${field.key}.value`)}
                      style={{ flexGrow: 1 }}
                    >
                      <Input placeholder="Поле" disabled={loading} />
                    </Form.Item>
                    <CloseOutlined onClick={() => operations.remove(field.name)} disabled={loading} />
                  </Flex>
                ))}
                <Button
                  type="dashed"
                  icon={<PlusCircleOutlined />}
                  onClick={() => operations.add()}
                  disabled={loading}
                >Додати поле
                </Button>
              </Flex>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Modal>
  );
};

