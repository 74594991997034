import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { App, Button, ColorPicker, Descriptions, Flex, Typography } from 'antd';
import { Table } from '../../components/Table/Table.jsx';
import { deleteCar, fetchCars, updateCar } from '../../slices/actions/cars.actions.js';
import { DataLabel, DataKey, AppRoute, PageTitle } from '../../const.js';
import { formatDateToUA } from '../../utils.js';
import { useTableSearch } from '../../hooks/useTableSearch.js';
import { useTableColumns } from '../../hooks/useTableColumns.js';
import { useTableSorter } from '../../hooks/useTableSorter.js';

const { Link, Text } = Typography;

export const CarsIndex = () => {
  const { entities: tableItems, ui } = useSelector((state) => state.cars);
  const { total, isLoading } = ui;
  const { message, modal } = App.useApp();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFieldChange = (row, name) => async (value) => {
    const { id } = row;
    const prevValue = row[name];
    try {
      if (value !== prevValue) {
        await dispatch(updateCar({ id, data: { ...row, [name]: value } })).unwrap();
        message.success('Запис успішно змінено!');
      }
    } catch (error) {
      message.error('Сталася помилка!');
    }
  };

  const handleColorChange = (row) => async (_, value) => {
    const { id } = row;
    const prevValue = row[DataKey.Color];
    try {
      if (value !== prevValue) {
        await dispatch(updateCar({ id, data: { ...row, [DataKey.Color]: value } })).unwrap();
        message.success('Запис успішно змінено!');
      }
    } catch (error) {
      message.error('Сталася помилка!');
    }
  };

  const handleEditClick = (id) => {
    navigate(`${AppRoute.Cars}/edit/${id}`);
  };

  const handleDeleteClick = async (id) => {
    const confirmed = await modal.confirm({
      title: 'Видалити вказаний запис?',
      okText: 'Так',
      cancelText: 'Ні',
    });

    if (confirmed) {
      await dispatch(deleteCar(id)).unwrap();
      message.success('Запис успішно видалено!');
    }
  };

  const getColumns = () => ([
    {
      key: DataKey.Id,
      dataIndex: DataKey.Id,
      title: DataLabel.Id,
      hidden: false,
      sorter: true,
      width: 75,
      render: (text) => (
        <Text ellipsis={{ tooltip: true }}>{text}</Text>
      ),
    },
    {
      key: DataKey.Brand,
      dataIndex: DataKey.Brand,
      title: DataLabel.Brand,
      hidden: false,
      sorter: true,
      ellipsis: true,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.Brand), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.Model,
      dataIndex: DataKey.Model,
      title: DataLabel.Model,
      hidden: false,
      sorter: true,
      ellipsis: true,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.Model), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.Color,
      dataIndex: DataKey.Color,
      title: DataLabel.Color,
      hidden: false,
      sorter: true,
      render: (color, row) => (
        <ColorPicker defaultValue={color} size="small" showText onChange={handleColorChange(row)} />
      ),
    },
    {
      key: DataKey.Year,
      dataIndex: DataKey.Year,
      title: DataLabel.Year,
      hidden: false,
      sorter: true,
      ellipsis: true,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.Year), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.Price,
      dataIndex: DataKey.Price,
      title: DataLabel.Price,
      hidden: false,
      sorter: true,
      ellipsis: true,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.Price), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.BuyAt,
      dataIndex: DataKey.BuyAt,
      title: DataLabel.BuyAt,
      hidden: false,
      sorter: true,
      ellipsis: true,
      render: (text) => (
        <Text ellipsis={{ tooltip: true }}>{formatDateToUA(text)}</Text>
      ),
    },
    {
      key: DataKey.Actions,
      dataIndex: null,
      title: DataLabel.Actions,
      hidden: false,
      ellipsis: true,
      width: 220,
      render: ({ id, deleted }) => (
        <Flex gap={8} vertical>
          <Button type="primary" onClick={() => handleEditClick(id)}>Редагувати</Button>
          <Button type={deleted ? 'primary' : 'dashed'} onClick={() => handleDeleteClick(id)} danger>
            {deleted ? 'Примусово видалити' : 'Видалити'}
          </Button>
        </Flex>
      ),
    },
  ]);

  const { search, handleSearchChange } = useTableSearch(ui.search);
  const { columns, handleColumnsSwitch, handleColumnsReset } = useTableColumns(getColumns);
  const { orderColumn, orderDirection, currentPage, perPage, handleTableChange } = useTableSorter(ui);

  const handleRowClick = (record) => (evt) => {
    if (evt.target.tagName === 'TD') {
      const {
        brand,
        model,
        year,
        color,
        engine,
        price,
        auctionLink,
        auctionFee,
        comment,
        buyAt,
      } = record;
      modal.info({
        title: 'Детальніше',
        content: (
          <Descriptions column={3} items={[
            {
              key: DataKey.Brand,
              label: DataLabel.Brand,
              children: brand,
            },
            {
              key: DataKey.Model,
              label: DataLabel.Model,
              children: model,
            },
            {
              key: DataKey.Year,
              label: DataLabel.Year,
              children: year,
            },
            {
              key: DataKey.Color,
              label: DataLabel.Color,
              children: color,
            },
            {
              key: DataKey.Engine,
              label: DataLabel.Engine,
              children: engine,
            },
            {
              key: DataKey.Price,
              label: DataLabel.Price,
              children: price,
            },
            {
              key: DataKey.Comment,
              label: DataLabel.Comment,
              children: comment,
              span: 3,
            },
            {
              key: DataKey.AuctionLink,
              label: DataLabel.AuctionLink,
              children: (<Link href={auctionLink}>{auctionLink}</Link>),
              span: 3,
            },
            {
              key: DataKey.AuctionFee,
              label: DataLabel.AuctionFee,
              children: auctionFee,
            },
            {
              key: DataKey.BuyAt,
              label: DataLabel.BuyAt,
              children: formatDateToUA(buyAt),
              span: 2,
            },
          ]}
          />),
        width: 800,
        closable: true,
        maskClosable: true,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchCars({
      orderColumn,
      orderDirection,
      currentPage,
      perPage,
      search,
    }));
  }, [dispatch, orderColumn, orderDirection, currentPage, perPage, search]);

  return (
    <>
      <Helmet>
        <title>{PageTitle.Cars}</title>
      </Helmet>
      <Table
        title={PageTitle.Cars}
        route={AppRoute.Cars}
        searchHelp="Шукати за назвою"
        columns={columns}
        dataSource={tableItems}
        rowKey={DataKey.Id}
        total={total}
        current={currentPage}
        pageSize={perPage}
        isLoading={isLoading}
        onSearchChange={handleSearchChange}
        onSwitchChange={handleColumnsSwitch}
        onResetChange={handleColumnsReset}
        onTableChange={handleTableChange}
        onTableRow={(record) => ({
          onClick: handleRowClick(record),
        })}
      />
    </>
  );
};
