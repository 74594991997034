import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Breadcrumb, Button, Input } from 'antd';
import { SearchOutlined, UserOutlined, DisconnectOutlined } from '@ant-design/icons';

import { logoutUser } from '../../slices/actions/user.actions';
import { AppRoute } from '../../const';
import { getTitle } from '../../utils';

function Header() {
  const { pathname } = useLocation();
  const email = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const breadcrumbs = [
    { title: <Link to={AppRoute.Root}>{getTitle(AppRoute.Root)}</Link> },
    { title: getTitle(pathname) },
  ];

  const handleLogout = (evt) => {
    evt.preventDefault();
    dispatch(logoutUser());
  };

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={10} style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Breadcrumb items={breadcrumbs} />
      </Col>
      <Col span={24} md={14} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 24,
      }}
      >
        <Input
          placeholder="Пошук..."
          prefix={<SearchOutlined />}
          style={{ maxWidth: 250 }}
          disabled
        />
        {
          email &&
          (
            <Link to={AppRoute.Root}>
              <Button type="link" icon={<UserOutlined />}>
                {email}
              </Button>
            </Link>
          )
        }
        <Link to="/" onClick={handleLogout}>
          <Button type="link" icon={<DisconnectOutlined />}>
            Вийти
          </Button>
        </Link>
      </Col>
    </Row>
  );
}

export default Header;
