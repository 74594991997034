import { useSelector } from 'react-redux';
import { Skeleton, Typography } from 'antd';
import { formatDateToUA } from '../../../../utils';

const { Paragraph, Text, Title } = Typography;

export const LeadInfoHeader = () => {
  const { lead } = useSelector((state) => state.lead);
  return (
    <>
      <Paragraph>
        {
          lead ?
            <Title level={3} copyable>Заявка №{lead?.id}</Title> :
            <Skeleton.Input block active style={{ width: '50%' }} />
        }
      </Paragraph>
      <Paragraph>
        {
          lead?.createdAt ?
            <Text>Створено: <Text copyable>{formatDateToUA(lead?.createdAt)}</Text></Text> :
            <Skeleton.Input block active style={{ width: '75%', height: 22 }} />
        }
      </Paragraph>
      <Paragraph>
        {
          lead?.updatedAt ?
            <Text>Останнє редагування: <Text copyable>{formatDateToUA(lead?.updatedAt)}</Text></Text> :
            <Skeleton.Input block active style={{ width: '75%',height: 22 }} />
        }
      </Paragraph>
    </>);
};
