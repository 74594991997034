import _ from 'lodash';
import { ValidateStatus, routesToTitles } from './const';

export const getTitle = (path) => routesToTitles[path];

export const formatDateToUA = (raw) => new Intl.DateTimeFormat('uk-UA', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}).format(new Date(raw));

export const truncateText = (text, length = 100) => _.truncate(text, length);

export const getValidateStatus = (errors, field) => errors[field] ? ValidateStatus.Error : ValidateStatus.Success;

export const getErrorsText = (errors, field) => {
  if (Array.isArray(errors[field])) {
    return errors[field].join('\n');
  }
  return null;
};

export const optionFilter = (input, option) =>
  (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
