import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertFetchParamsForBackend, EntitiesAdapter } from '../../adapter.js';

const ENDPOINT = '/api/clients';

export const fetchClients = createAsyncThunk(
  'clients/fetch',
  async (params = {}, { extra }) => {
    const { api } = extra;
    const response = await api.get(ENDPOINT, {
      params: convertFetchParamsForBackend(params),
    });
    return response.data;
  },
);

export const fetchOneClient = createAsyncThunk(
  'clients/fetch/one',
  async (id, { extra }) => {
    const { api } = extra;
    const response = await api.get(`${ENDPOINT}/${id}`);
    const { data } = response.data;
    return EntitiesAdapter.clientToFrontend(data);
  },
);

export const createClient = createAsyncThunk(
  'clients/create',
  async (data = {}, { extra, rejectWithValue }) => {
    const { api } = extra;
    try {
      const response = await api.post(ENDPOINT, EntitiesAdapter.clientToBackend(data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const updateClient = createAsyncThunk(
  'clients/update',
  async (payload, { extra, dispatch, getState, rejectWithValue }) => {
    const { id, data } = payload;
    const { api } = extra;
    try {
      const response = await api.patch(`${ENDPOINT}/${id}`, EntitiesAdapter.clientToBackend(data));
      const state = getState();
      dispatch(fetchClients(state.clients.ui));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const deleteClient = createAsyncThunk(
  'clients/delete',
  async (id, { getState, dispatch, extra }) => {
    const { api } = extra;
    const response = await api.delete(`${ENDPOINT}/${id}`);
    const state = getState();
    dispatch(fetchClients(state.clients.ui));
    return response.data;
  },
);
