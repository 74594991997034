import { createSlice } from '@reduxjs/toolkit';
import { DataKey, SortOrderReduced } from '../../const.js';
import { fetchOneSource, fetchSources } from '../actions/sources.actions.js';
import { convertFetchMetaToFrontend, EntitiesAdapter } from '../../adapter.js';

const initialState = {
  entities: [],
  currentEntity: {},
  ids: [],
  ui: {
    [DataKey.OrderColumn]: DataKey.CreatedAt,
    [DataKey.OrderDirection]: SortOrderReduced.Descend,
    [DataKey.PerPage]: 10,
    [DataKey.CurrentPage]: 1,
    [DataKey.Search]: '',
    [DataKey.Total]: 0,
    [DataKey.IsLoading]: false,
  },
};

export const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.pending, (state) => {
        state.ui = {
          ...state.ui,
          [DataKey.IsLoading]: true,
        };
      })
      .addCase(fetchSources.fulfilled, (state, { payload }) => {
        const { data, meta } = payload;
        state.entities = data.map(EntitiesAdapter.sourceToFrontend);
        state.ids = data.map((item) => item[DataKey.Id]);
        state.ui = {
          ...state.ui,
          ...convertFetchMetaToFrontend(meta),
          [DataKey.IsLoading]: false,
        };
      })
      .addCase(fetchOneSource.fulfilled, (state, { payload }) => {
        state.currentEntity = payload;
      })
      .addCase(fetchSources.rejected, (state) => {
        state.ui = {
          ...state.ui,
          [DataKey.IsLoading]: false,
        };

      });
  },
});

export default sourcesSlice.reducer;
