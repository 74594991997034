import { useState } from 'react';
import { SortOrder, SortOrderReduced } from '../const';

export const useTableSorter = (ui) => {
  const [currentPage, setCurrentPage] = useState(ui.currentPage);
  const [perPage, setPerPage] = useState(ui.perPage);
  const [orderColumn, setOrderColumn] = useState(ui.orderColumn);
  const [orderDirection, setOrderDirection] = useState(ui.orderDirection);

  const handleTableChange = (pagination, _filters, sorter) => {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorter;

    setCurrentPage((prev) => {
      if (current !== prev) {
        return current;
      }
    });
    setPerPage((prev) => {
      if (pageSize !== prev) {
        return pageSize;
      }
    });

    setOrderColumn(columnKey);

    setOrderDirection(() =>
      order === SortOrder.Ascend ?
        SortOrderReduced.Ascend :
        SortOrderReduced.Descend
    );
  };

  return { orderColumn, orderDirection, currentPage, perPage, handleTableChange };
};
