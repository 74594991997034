import { Provider } from 'react-redux';
import { Route, Routes, Navigate, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { App as AntdApp, ConfigProvider } from 'antd';

import { Login } from '../../pages/Login/Login.jsx';
import { Main } from '../../layouts/Main.jsx';
import { PrivateRoute } from '../PrivateRoute/PrivateRoute.jsx';
import { ClientsIndex } from '../../pages/Clients/Index.jsx';
import { ClientsEdit } from '../../pages/Clients/Edit.jsx';
import { ClientsCreate } from '../../pages/Clients/Create.jsx';
import { CarsIndex } from '../../pages/Cars/Index.jsx';
import { CarsEdit } from '../../pages/Cars/Edit.jsx';
import { CarsCreate } from '../../pages/Cars/Create.jsx';
import { LeadsIndex } from '../../pages/Leads/Index.jsx';
import { LeadsEdit } from '../../pages/Leads/Edit.jsx';
import { LeadsCreate } from '../../pages/Leads/Create.jsx';
import { OfficesIndex } from '../../pages/Offices/Index.jsx';
import { OfficesEdit } from '../../pages/Offices/Edit.jsx';
import { OfficesCreate } from '../../pages/Offices/Create.jsx';
import { SourcesIndex } from '../../pages/Sources/Index.jsx';
import { SourcesEdit } from '../../pages/Sources/Edit.jsx';
import { SourcesCreate } from '../../pages/Sources/Create.jsx';
import { StatusesIndex } from '../../pages/Statuses/Index.jsx';
import { StatusesEdit } from '../../pages/Statuses/Edit.jsx';
import { StatusesCreate } from '../../pages/Statuses/Create.jsx';
import { NotFound } from '../../pages/NotFound/NotFound.jsx';

import store from '../../slices/index.js';
import { theme } from '../../theme.js';
import history from '../../history.js';
import { AppRoute, AuthorizationStatus } from '../../const.js';

export const App = () => (
  <Provider store={store}>
    <ConfigProvider theme={theme}>
      <AntdApp className="App">
        <HistoryRouter history={history}>
          <Routes>
            <Route path="" element={<Navigate to={AppRoute.Leads} />} />
            <Route path={AppRoute.Root} element={<Navigate to={AppRoute.Leads} />} />
            <Route path={AppRoute.Leads} element={<Main />}>
              <Route
                index
                path={AppRoute.Leads}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <LeadsIndex />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${AppRoute.Leads}/create`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <LeadsCreate />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${AppRoute.Leads}/edit/:id`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <LeadsEdit />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path={AppRoute.Clients} element={<Main />}>
              <Route
                index
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <ClientsIndex />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Clients}/create`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <ClientsCreate />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Clients}/edit/:id`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <ClientsEdit />
                  </PrivateRoute>
                }
              >
              </Route>
            </Route>
            <Route path={AppRoute.Cars} element={<Main />}>
              <Route
                index
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <CarsIndex />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Cars}/create`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <CarsCreate />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Cars}/edit/:id`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <CarsEdit />
                  </PrivateRoute>
                }
              >
              </Route>
            </Route>
            <Route path={AppRoute.Offices} element={<Main />}>
              <Route
                index
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <OfficesIndex />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Offices}/create`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <OfficesCreate />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Offices}/edit/:id`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <OfficesEdit />
                  </PrivateRoute>
                }
              >
              </Route>
            </Route>
            <Route path={AppRoute.Sources} element={<Main />}>
              <Route
                index
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <SourcesIndex />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Sources}/create`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <SourcesCreate />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Sources}/edit/:id`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <SourcesEdit />
                  </PrivateRoute>
                }
              >
              </Route>
            </Route>
            <Route path={AppRoute.Statuses} element={<Main />}>
              <Route
                index
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <StatusesIndex />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Statuses}/create`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <StatusesCreate />
                  </PrivateRoute>
                }
              >
              </Route>
              <Route
                path={`${AppRoute.Statuses}/edit/:id`}
                element={
                  <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={AppRoute.Login}>
                    <StatusesEdit />
                  </PrivateRoute>
                }
              >
              </Route>
            </Route>
            <Route
              path={AppRoute.Login}
              element={
                <PrivateRoute restrictedFor={AuthorizationStatus.Auth} redirectTo={AppRoute.Root}>
                  <Login />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </HistoryRouter>
      </AntdApp>
    </ConfigProvider>
  </Provider>
);
