import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { StatusForm } from '../../components/StatusForm/StatusForm';
import { AppRoute } from '../../const';
import { createStatus } from '../../slices/actions/statuses.actions';

export const StatusesCreate = () => {
  const { modal } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    await dispatch(createStatus(values)).unwrap().then(() => {
      modal.success({
        title: 'Успіх',
        content: 'Статус успішно створений!',
        onOk: () => navigate(AppRoute.Statuses),
      });
    });
  };

  return (
    <div>
      <Helmet>
        <title>Додати новий статус</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Statuses}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <StatusForm title="Створити статус" onFinish={handleFormSubmit} />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
