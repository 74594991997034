export const RawAuthData = {
  AccessToken: 'access_token',
  TokenType: 'token_type',
  ExpiresIn: 'expires_in',
};

export const AuthData = {
  AccessToken: 'accessToken',
  TokenType: 'tokenType',
  ExpiresIn: 'expiresIn',
};

export const RawFetchParams = {
  Id: 'id',
  Title: 'title',
  Comment: 'comment',
  CreatedAt: 'created_at',
  UpdatedAt: 'updated_at',
  Search: 'search',
};

export const FetchParams = {
  Id: 'id',
  Title: 'title',
  Comment: 'comment',
  CreatedAt: 'createdAt',
  UpdatedAt: 'updatedAt',
  Search: 'search',
};

/* BACKEND */

export const RawRole = {
  Id: 'id', // number
  Name: 'name', // string
  ExternalName: 'external_name', // string
  CreatedAt: 'created_at', // string
  UpdatedAt: 'updated_at', // string
  Deleted: 'deleted', // boolean
};

export const RawManager = {
  Id: 'id', // number
  Name: 'name', // string
  Email: 'email', // string
  Role: 'role', // RawRole
  Deleted: 'deleted', // boolen
};

export const RawOffice = {
  Id: 'id', // number
  Title: 'title', // string
  Comment: 'comment', // string
  CreatedAt: 'created_at', // string
  UpdatedAt: 'updated_at', // string
  DeletedAt: 'deleted_at', // string
  Deleted: 'deleted', // boolean
};

export const RawStatus = {
  Id: 'id', // number
  Title: 'title', // string
  Comment: 'comment', // string
  CreatedAt: 'created_at', // string
  UpdatedAt: 'updated_at', // string
  DeletedAt: 'deleted_at', // string
  Deleted: 'deleted', // boolean
  Color: 'color',
};

export const RawSource = {
  Id: 'id', // number
  Title: 'title', // string
  Comment: 'comment', // string
  CreatedAt: 'created_at', // string
  UpdatedAt: 'updated_at', // string
  DeletedAt: 'deleted_at', // string
  Deleted: 'deleted', // boolean
};

export const RawAdditionalField = {
  Name: 'name', // string
  Value: 'value', // string
};

export const RawLead = {
  Id: 'id', // number
  Manager: 'manager', // RawManager
  Office: 'office', // RawOffice
  Status: 'status', // RawStatus
  Client: 'client', // RawClient
  Source: 'source', // RawSource
  AdditionalFields: 'additional_fields', // RawAdditionalField[]
  Comment: 'comment', // string
  Ip: 'ip', // string
  CreatedAt: 'created_at', // string
  UpdatedAt: 'updated_at', // string
  Deleted: 'deleted', // boolean
  StatusId: 'status_id', // number[]
  ConnectionType: 'connection_type', // string
};

/* FRONTEND */

export const RawCar = {
  Id: 'id', // number
  Brand: 'brand', // string
  Model: 'model', // string
  Year: 'year', // number
  Vin: 'vin', // string
  Fuel: 'fuel', // string
  Location: 'locatiom', // string
  Color: 'color', // string
  Engine: 'engine', // number
  Price: 'price', // number
  AuctionLink: 'auction_link', // string
  AuctionFee: 'auction_fee', // number
  Comment: 'comment', // string
  BuyAt: 'buy_at', // string
  Deleted: 'deleted', // boolean
};

export const Car = {
  Id: 'id', // number
  Brand: 'brand', // string
  Model: 'model', // string
  Year: 'year', // number
  Vin: 'vin', // string
  Fuel: 'fuel', // string
  Location: 'locatiom', // string
  Color: 'color', // string
  Engine: 'engine', // number
  Price: 'price', // number
  AuctionLink: 'auctionLink', // string
  AuctionFee: 'auctionFee', // number
  Comment: 'comment', // string
  BuyAt: 'buyAt', // string
  Deleted: 'deleted', // boolean
};

export const Role = {
  Id: 'id', // number
  Name: 'name', // string
  ExternalName: 'externalName', // string
  CreatedAt: 'createdAt', // string
  UpdatedAt: 'updatedAt', // string
  Deleted: 'deleted', // boolean
};

export const Manager = {
  Id: 'id', // number
  Name: 'name', // string
  Email: 'email', // string
  Role: 'role', // RawRole
  Deleted: 'deleted', // boolen
};

export const Office = {
  Id: 'id', // number
  Title: 'title', // string
  Comment: 'comment', // string
  CreatedAt: 'createdAt', // string
  UpdatedAt: 'updatedAt', // string
  DeletedAt: 'deletedAt', // string
  Deleted: 'deleted', // boolean
};

export const Status = {
  Id: 'id', // number
  Title: 'title', // string
  Comment: 'comment', // string
  CreatedAt: 'createdAt', // string
  UpdatedAt: 'updatedAt', // string
  DeletedAt: 'deletedAt', // string
  Deleted: 'deleted', // boolean
  Color: 'color',
};

export const RawClient = {
  Id: 'id', // number
  FirstName: 'first_name', // string
  LastName: 'last_name', // string
  MiddleName: 'middle_name', // string
  FullName: 'full_name', // string
  Email: 'email', // string
  Phone: 'phone', // string
  IpnCode: 'ipn_code', // string
  PassCode: 'pass_code', // string
  Code: 'code', // string
  PassId: 'pass_id', // string
  Comment: 'comment', // string
  CreatedAt: 'created_at', // string
  UpdatedAt: 'updated_at', // string
  DeletedAt: 'deleted_at', // boolean
  Deleted: 'deleted', // boolean
  AdditionalFields: 'additional_fields', // RawAdditionalField[]
};

export const Client = {
  Id: 'id', // number
  FirstName: 'firstName', // string
  LastName: 'lastName', // string
  MiddleName: 'middleName', // string
  FullName: 'fullName', // string
  Email: 'email', // string
  Phone: 'phone', // string
  IpnCode: 'ipnCode', // string
  PassCode: 'passCode', // string
  Code: 'code', // string
  PassId: 'passId', // string
  Comment: 'comment', // string
  CreatedAt: 'createdAt', // string
  UpdatedAt: 'updatedAt', // string
  DeletedAt: 'deletedAt', // boolean
  Deleted: 'deleted', // boolean
  AdditionalFields: 'additionalFields', // AdditionalField[]
};

export const Source = {
  Id: 'id', // number
  Title: 'title', // string
  Comment: 'comment', // string
  CreatedAt: 'createdAt', // string
  UpdatedAt: 'updatedAt', // string
  DeletedAt: 'deletedAt', // string
  Deleted: 'deleted', // boolean
};

export const AdditionalField = {
  Name: 'name', // string
  Value: 'value', // string
};

export const Lead = {
  Id: 'id', // number
  Manager: 'manager', // Manager
  Office: 'office', // Office
  Status: 'status', // Status
  Client: 'client', // Client
  Source: 'source', // Source
  AdditionalFields: 'additionalFields', // AdditionalField[]
  Comment: 'comment', // string
  Ip: 'ip', // string
  CreatedAt: 'createdAt', // string
  UpdatedAt: 'updatedAt', // string
  Deleted: 'deleted', // boolean
  StatusId: 'statusId', // number[]
  ConnectionType: 'connectionType', // string
};
