export const SEARCH_DEBOUNCE = 300;

export const SocialColor = {
  Viber: '#7360f2',
  Telegram: '#2aabee',
  Signal: '#3a76f0',
};

export const AuthorizationStatus = {
  Auth: 'AUTH',
  NoAuth: 'NO_AUTH',
  Unknown: 'UNKNOWN',
};

export const AppRoute = {
  Root: '/app',
  Login: '/app/login',
  Logout: '/app/logout',
  Leads: '/app/leads',
  Clients: '/app/clients',
  Cars: '/app/cars',
  Offices: '/app/offices',
  Sources: '/app/sources',
  Statuses: '/app/statuses',
  Payments: '/app/payments',
  Settings: '/app/settings',
};

export const Endpoint = {
  Login: '/api/login',
  Logout: '/api/logout',
  Status: '/api/user',
  LeadsStatuses: '/api/leads-statuses',
};

export const HttpCode = {
  NotFound: 404,
  NoAuth: 401,
};

export const ValidateStatus = {
  Default: '',
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  Validating: 'validating',
};

export const PageTitle = {
  Root: 'CRM',
  Leads: 'Заявки',
  Clients: 'Клієнти',
  Cars: 'Авто',
  Offices: 'Офіси',
  Sources: 'Джерела трафіку',
  Statuses: 'Статуси',
  Payments: 'Платежі',
  Settings: 'Налаштування',
};

export const routesToTitles = {
  [AppRoute.Root]: 'CRM',
  [AppRoute.Dashboard]: 'Панель управління',
  [AppRoute.Clients]: 'Список клієнтів',
  [`${AppRoute.Clients}/create`]: 'Додати нового клієнта',
  [`${AppRoute.Clients}/edit`]: 'Редагувати клієнта',
  [AppRoute.Leads]: 'Список заявок',
  [`${AppRoute.Leads}/create`]: 'Додати нову заявку',
  [`${AppRoute.Leads}/edit`]: 'Редагувати заявку',
  [AppRoute.Offices]: 'Список офісів',
  [`${AppRoute.Offices}/create`]: 'Додати новий офіс',
  [`${AppRoute.Offices}/edit`]: 'Редагувати офіс',
  [AppRoute.Sources]: 'Список джерел трафіку',
  [`${AppRoute.Sources}/create`]: 'Додати нове джерело трафіку',
  [`${AppRoute.Sources}/edit`]: 'Редагувати джерело трафіку',
  [AppRoute.Statuses]: 'Список статусів',
  [`${AppRoute.Statuses}/create`]: 'Додати новий статус',
  [`${AppRoute.Statuses}/edit`]: 'Редагувати статус',
  [AppRoute.Cars]: 'Список авто',
  [`${AppRoute.Cars}/create`]: 'Додати нове авто',
  [`${AppRoute.Cars}/edit`]: 'Редагувати авто',
};

export const RawDataKey = {
  Id: 'id',
  Title: 'title',
  Comment: 'comment',
  CreatedAt: 'created_at',
  UpdatedAt: 'updated_at',

  OrderColumn: 'order_column',
  OrderDirection: 'order_direction',
  PerPage: 'per_page',
  CurrentPage: 'current_page',
  Search: 'search',
  Total: 'total',
  IsLoading: 'is_loading',

  StatusId: 'status_id',
  Color: 'color',

  AdditionalFields: 'additional_fields',
  Vin: 'vin',

  IpnCode: 'ipn_code',
  PassCode: 'pass_code',
};

export const DataKey = {
  Id: 'id',
  Title: 'title',
  Comment: 'comment',
  CreatedAt: 'createdAt',
  UpdatedAt: 'updatedAt',

  Client: 'client',

  OrderColumn: 'orderColumn',
  OrderDirection: 'orderDirection',
  PerPage: 'perPage',
  CurrentPage: 'currentPage',
  Search: 'search',
  Total: 'total',
  IsLoading: 'isLoading',

  StatusId: 'statusId',

  FirstName: 'firstName',
  LastName: 'lastName',
  MiddleName: 'middleName',
  FullName: 'fullName',
  Email: 'email',
  Phone: 'phone',
  Code: 'code',
  PassId: 'passId',
  Deleted: 'deleted',
  Leads: 'leads',
  Actions: 'actions',
  Status: 'status',
  Source: 'source',
  Manager: 'manager',

  Brand: 'brand',
  Model: 'model',
  Color: 'color',
  Year: 'year',
  Engine: 'engine',
  Price: 'price',
  BuyAt: 'buyAt',
  AuctionLink: 'auctionLink',
  AuctionFee: 'auctionFee',

  AdditionalFields: 'additionalFields',
  Vin: 'vin',

  Fuel: 'fuel',
  Location: 'location',

  IpnCode: 'ipnCode',
  PassCode: 'passCode',
};

export const normalizedKeysToRaw = {
  [DataKey.Id]: RawDataKey.Id,
  [DataKey.Title]: RawDataKey.Title,
  [DataKey.Comment]: RawDataKey.Comment,
  [DataKey.CreatedAt]: RawDataKey.CreatedAt,
  [DataKey.UpdatedAt]: RawDataKey.UpdatedAt,

  [DataKey.OrderColumn]: RawDataKey.OrderColumn,
  [DataKey.OrderDirection]: RawDataKey.OrderDirection,
  [DataKey.PerPage]: RawDataKey.PerPage,
  [DataKey.CurrentPage]: RawDataKey.CurrentPage,
  [DataKey.Search]: RawDataKey.Search,
  [DataKey.Total]: RawDataKey.Total,
  [DataKey.IsLoading]: RawDataKey.IsLoading,
};

export const DataLabel = {
  Title: 'Назва',

  FirstName: 'Ім\'я',
  MiddleName: 'По батькові',
  LastName: 'Прізвище',
  Email: 'E-mail',
  Phone: 'Телефон',
  Id: 'id',
  PassId: 'Ідентифікатор',
  CreatedAt: 'Створено',
  UpdatedAt: 'Редаговано',
  Comment: 'Коментар',
  Actions: 'Дії',
  Status: 'Статус',
  Source: 'Джерело',
  Manager: 'Менеджер',

  Brand: 'Марка',
  Model: 'Модель',
  Year: 'Рік',
  Color: 'Колір',
  Engine: 'Двигун',
  Price: 'Ціна',
  BuyAt: 'Придбано',
  AuctionLink: 'Посилання на аукціон',
  AuctionFee: 'Аукціонний збір',
  Vin: 'VIN-код',

  Fuel: 'Пальне',
  Location: 'Локація',

  IpnCode: 'РНОКПП',
  PassCode: 'Паспорт',
};

export const SortOrder = {
  Ascend: 'ascend',
  Descend: 'descend',
};

export const SortOrderReduced = {
  Ascend: 'asc',
  Descend: 'desc',
};
