import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { App, Button, Card, Flex, Input, Select, Table, Tooltip, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsFilter } from '../../components/ColumnsFilter/ColumnsFilter';
import { StatusSelect } from '../../components/StatusSelect/StatusSelect.tsx';
import { StatusesBar } from '../../components/StatusesBar/StatusesBar.tsx';

import { fetchLeads, updateLead } from '../../slices/actions/leads.actions';

import { AppRoute, DataKey, DataLabel, PageTitle, SortOrder, SortOrderReduced } from '../../const';
import { formatDateToUA, optionFilter } from '../../utils';
import { useTableSearch } from '../../hooks/useTableSearch';
import { fetchStatuses } from '../../slices/actions/statuses.actions.js';

const { Text } = Typography;

const sortDirections = ['ascend', 'descend', 'ascend'];

const TextItem = ({ text }) => <Text ellipsis={{ tooltip: true }}>{text}</Text>;

TextItem.propTypes = {
  text: PropTypes.string,
};

const DateItem = ({ date }) => <Text ellipsis={{ tooltip: true }}>{formatDateToUA(date)}</Text>;

DateItem.propTypes = {
  date: PropTypes.string,
};

export const LeadsIndex = () => {
  const { entities: leads, ui } = useSelector((state) => state.leads);
  const { entities: sources } = useSelector((state) => state.sources);
  const { entities: statuses } = useSelector((state) => state.statuses);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedStatuses, setSelectedStatuses] = useState(statuses.map((status) => status.id));
  const { search, handleSearchChange } = useTableSearch(ui.search);
  const { message } = App.useApp();

  const handleTableChange = (pagination, _filters, sorter) => {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorter;

    const orderColumn = columnKey ?? ui.orderColumn;
    const orderDirection = order === SortOrder.Descend ? SortOrderReduced.Descend : SortOrderReduced.Ascend;

    dispatch(fetchLeads({
      orderColumn,
      orderDirection,
      currentPage: current,
      perPage: pageSize,
      statusId: selectedStatuses,
    }));
  };

  const handleTagsChange = (id) => {
    const isChecked = selectedStatuses.includes(id);
    if (isChecked) {
      setSelectedStatuses((prev) => prev.filter((itemId) => itemId !== id));
    } else {
      setSelectedStatuses((prev) => [...prev, id]);
    }
  };

  const handleTagsReset = () => {
    setSelectedStatuses(statuses.map((status) => status.id));
  };

  const handleRowClick = ({ id }) => (evt) => {
    if (evt.target.tagName === 'TD') {
      navigate(`${AppRoute.Leads}/edit/${id}`);
    }
  };

  useEffect(() => {
    dispatch(fetchLeads());
    dispatch(fetchStatuses());
  }, [dispatch]);

  useEffect(() => {
    setSelectedStatuses(statuses.map((status) => status.id));
  }, [statuses]);

  useEffect(() => {
    dispatch(fetchLeads({
      orderColumn: ui.orderColumn,
      orderDirection: ui.orderDirection,
      currentPage: ui.currentPage,
      perPage: ui.perPage,
      statusId: selectedStatuses,
      search,
    }));
  }, [dispatch, selectedStatuses, search]);

  const handleSourceChange = async (value, row) => {
    const { id } = row;
    try {
      await dispatch(updateLead({ id, data: { ...row, sourceId: value } })).unwrap();
      message.success('Запис успішно змінено!');
    } catch (error) {
      message.error('Сталася помилка!');
    }
  };

  const handleStatusChange = async (value, row) => {
    const { id } = row;
    try {
      await dispatch(updateLead({ id, data: { ...row, statusId: value } })).unwrap();
      message.success('Запис успішно змінено!');
    } catch (error) {
      message.error('Сталася помилка!');
    }
  };

  const initialColumns = [
    {
      key: DataKey.Id,
      dataIndex: DataKey.Id,
      title: DataLabel.Id,
      hidden: false,
      sorter: true,
      ellipsis: true,
      width: 75,
      render: (text) => <TextItem text={text.toString()} />
    },
    {
      key: DataKey.CreatedAt,
      dataIndex: DataKey.CreatedAt,
      title: DataLabel.CreatedAt,
      hidden: false,
      sorter: true,
      ellipsis: true,
      render: (date) => <DateItem date={date} />,
    },
    {
      key: DataKey.Manager,
      dataIndex: DataKey.Manager,
      title: DataLabel.Manager,
      hidden: false,
      sorter: true,
      render: (_text, record) => (
        <Select
          showSearch
          defaultValue={record?.manager?.name}
          options={[{ value: record?.manager?.id, label: record?.manager?.name }]}
          style={{ width: 100 }}
          filterOption={optionFilter}
          disabled
        />
      ),
    },
    {
      key: DataKey.FirstName,
      dataIndex: DataKey.Client,
      title: DataLabel.FirstName,
      hidden: false,
      sorter: false,
      ellipsis: true,
      render: (_text, record) => (
        <Text ellipsis={{ tooltip: true }}>{record?.client?.firstName}</Text>
      ),
    },
    {
      key: DataKey.Phone,
      dataIndex: DataKey.Client,
      title: DataLabel.Phone,
      hidden: false,
      sorter: false,
      ellipsis: true,
      render: (_text, record) => (
        <Text ellipsis={{ tooltip: true }}>{record?.client?.phone}</Text>
      ),
    },
    {
      key: DataKey.Source,
      dataIndex: DataKey.Source,
      title: DataLabel.Source,
      hidden: false,
      sorter: true,
      render: (_text, record) => (
        <Select
          showSearch
          defaultValue={record.source?.title}
          options={sources.map(({ id: value, title: label }) => ({ value, label }))}
          style={{ width: 150 }}
          onChange={(value) => handleSourceChange(value, record)}
        />
      ),
    },
    {
      key: DataKey.Comment,
      dataIndex: DataKey.Comment,
      title: DataLabel.Comment,
      hidden: false,
      sorter: false,
      ellipsis: true,
      render: (text) => (
        <Text ellipsis={{ tooltip: true }} style={{ maxWidth: 200 }}>{text}</Text>
      ),
    },
    {
      key: DataKey.Status,
      dataIndex: DataKey.Status,
      title: DataLabel.Status,
      hidden: false,
      sorter: true,
      render: (_text, record) => (
        <StatusSelect
          currentStatus={record.status}
          statuses={statuses}
          onChange={(value) => handleStatusChange(value, record)}
        />
      ),
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(initialColumns.map((column) => column.key));

  const columns = initialColumns.map((column) => ({
    ...column,
    hidden: !visibleColumns.includes(column.key),
  }));

  const handleColumnsSwitch = (key) => {
    setVisibleColumns((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      }
      return [...prev, key];
    });
  };

  const handleColumnsReset = () => {
    setVisibleColumns(initialColumns.map((column) => column.key));
  };

  return (
    <>
      <Helmet>
        <title>{PageTitle.Leads}</title>
      </Helmet>
      <Card
        title={PageTitle.Leads}
        extra={
          <Flex align="center" gap={24}>
            <Tooltip title="Шукати за назвою">
              <Input
                placeholder="Пошук..."
                onChange={handleSearchChange}
                prefix={<SearchOutlined />}
              />
            </Tooltip>
            <ColumnsFilter
              columns={columns}
              onSwitchChange={handleColumnsSwitch}
              onResetChange={handleColumnsReset}
            />
            <Link to={`${AppRoute.Leads}/create`}>
              <Button type="primary" shape="round" icon={<PlusOutlined />}>
                Додати
              </Button>
            </Link>
          </Flex>
        }
      >
        <StatusesBar
          statuses={statuses}
          selected={selectedStatuses}
          onClick={handleTagsChange}
          onReset={handleTagsReset}
        />
        <Table
          columns={columns}
          dataSource={leads}
          rowKey={DataKey.Id}
          loading={ui.isLoading}
          pagination={{
            total: ui.total,
            current: ui.currentPage,
            pageSize: ui.perPage,
            showSizeChanger: true,
          }}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: handleRowClick(record),
          })}
          scroll={{ x: true }}
        />
      </Card>
    </>
  );
};
