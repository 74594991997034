import { Button, Flex, Popover, Switch, Typography } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { useState } from 'react';
import PropTypes from 'prop-types';

const { Text } = Typography;

export const ColumnsFilter = (props) => {
  const { columns, onSwitchChange, onResetChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const isButtonDisabled = columns.every((column) => !column.hidden);

  const handleOpenClick = (evt) => {
    evt.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  const handleResetClick = () => {
    setIsOpen(() => false);
    onResetChange();
  };

  return (
    <Popover
      open={isOpen}
      placement="bottom"
      arrow={false}
      trigger="click"
      content={(
        <Flex vertical gap={16}>
          {columns.map((column) => {
            const { key, title, hidden } = column;
            const isChecked = !hidden;
            return (
              <Flex align="center" gap={8} component="label" key={key}>
                <Switch checked={isChecked} onChange={() => onSwitchChange(key)} />
                <Text strong>{title}</Text>
              </Flex>
            );
          })}
          <Button
            type="primary"
            onClick={handleResetClick}
            disabled={isButtonDisabled}
          >
                        Скинути
          </Button>
        </Flex>
      )}
    >
      <Button
        type="dashed"
        onClick={handleOpenClick}
        icon={<FilterFilled />}
      >Фільтрувати колонки
      </Button>
    </Popover>
  );
};

ColumnsFilter.propTypes = { columns: PropTypes.any, onSwitchChange: PropTypes.any, onResetChange: PropTypes.any };
