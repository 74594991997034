import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Card, Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ClientForm } from './Components/ClientForm/ClientForm';
import { LeadDetails } from './Components/LeadDetails/LeadDetails';
import { AppRoute } from '../../const';
import { clearLeadData } from '../../slices/slices/lead.slice';

function LeadsCreate() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearLeadData());
    return () => {
      dispatch(clearLeadData());
    };
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Додати заявку</title>
      </Helmet>
      <Link to={AppRoute.Leads}>
        <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
      </Link>
      <Card title="Додати заявку">
        <Row gutter={32}>
          <Col span={8}>
            <ClientForm />
          </Col>
          <Col span={16}>
            <LeadDetails />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export { LeadsCreate };
