import { useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { SEARCH_DEBOUNCE } from '../const';

export const useTableSearch = (defaultValue) => {
  const [search, setSearch] = useState(defaultValue);
  const debouncedSearch = useDebounce(search, SEARCH_DEBOUNCE);

  const handleSearchChange = (evt) => {
    const { value } = evt.target;
    setSearch(value);
  };

  return { search: debouncedSearch, handleSearchChange };
};
