import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertFetchParamsForBackend, EntitiesAdapter } from '../../adapter.js';

const ENDPOINT = '/api/cars';

export const fetchCars = createAsyncThunk(
  'cars/fetch',
  async (params = {}, { extra }) => {
    const { api } = extra;
    const response = await api.get(ENDPOINT, {
      params: convertFetchParamsForBackend(params),
    });
    return response.data;
  },
);

export const fetchOneCar = createAsyncThunk(
  'cars/fetch/one',
  async (id, { extra }) => {
    const { api } = extra;
    const response = await api.get(`${ENDPOINT}/${id}`);
    const { data } = response.data;
    return EntitiesAdapter.carToFrontend(data);
  },
);

export const createCar = createAsyncThunk(
  'cars/create',
  async (data = {}, { extra, rejectWithValue }) => {
    const { api } = extra;
    try {
      const response = await api.post(ENDPOINT, EntitiesAdapter.carToBackend(data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const updateCar = createAsyncThunk(
  'cars/update',
  async (payload, { extra, dispatch, getState, rejectWithValue }) => {
    const { id, data } = payload;
    const { api } = extra;
    try {
      const response = await api.patch(`${ENDPOINT}/${id}`, EntitiesAdapter.carToBackend(data));
      const state = getState();
      dispatch(fetchCars(state.cars.ui));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const deleteCar = createAsyncThunk(
  'cars/delete',
  async (id, { getState, dispatch, extra }) => {
    const { api } = extra;
    const response = await api.delete(`${ENDPOINT}/${id}`);
    const state = getState();
    dispatch(fetchCars(state.cars.ui));
    return response.data;
  },
);
