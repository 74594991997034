import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout, theme } from 'antd';
import Sidenav from './Parts/Sidenav.jsx';
import Header from './Parts/Header.jsx';
import { AppRoute } from '../const.js';

const { Header: AntHeader, Sider, Content } = Layout;
const { useToken } = theme;

const SiderWidth = {
  Width: 300,
  CollapsedWidth: 80,
};

const getContentLayoutWidth = (isSiderCollapsed) =>
  isSiderCollapsed ? SiderWidth.CollapsedWidth : SiderWidth.Width;

export const Main = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    /* axios.get('/api/user')
            .then(response => {
                axios.get('/api/abilities')
                    .then(response => {
                        const { can, rules } = new AbilityBuilder(Ability);
                        can(response.data);
                        ability.update(rules);
                    })
            })
            .catch(error => {
                if (error.response.status === 401) {
                    navigate('/login')
                }
            }) */
  });


  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const { token } = useToken();
  const handleCollapseButtonClick = (value) => setIsSiderCollapsed(value);

  useEffect(() => {
    setIsSiderCollapsed(pathname.includes(`${AppRoute.Leads}/create`) || pathname.includes(`${AppRoute.Leads}/edit`));
  }, [pathname]);

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        width={SiderWidth.Width}
        theme="light"
        collapsible
        collapsed={isSiderCollapsed}
        collapsedWidth={SiderWidth.CollapsedWidth}
        onCollapse={handleCollapseButtonClick}
        style={{
          position: 'fixed',
          height: '100%',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Sidenav />
      </Sider>
      <Layout style={{
        minHeight: '100vh',
        paddingLeft: getContentLayoutWidth(isSiderCollapsed),
        transition: `padding-left ${token.motionDurationMid}`,
      }}
      >
        <AntHeader>
          <Header />
        </AntHeader>
        <Content>
          <div style={{ padding: 12 }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
