import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { RocketOutlined, TeamOutlined, CarOutlined, SettingOutlined, HomeOutlined, AreaChartOutlined, HourglassOutlined } from '@ant-design/icons';

import { Logo } from '../../components/Logo/Logo.jsx';
import { AppRoute } from '../../const.js';

const NavItem = {
  Root: 'root',
  Leads: 'leads',
  LeadsList: 'leads-list',
  LeadsCreate: 'leads-create',
  LeadsEdit: 'leads-edit',
  LeadsTasks: 'leads-tasks',
  Clients: 'clients',
  ClientsList: 'clients-list',
  ClientsCreate: 'clients-create',
  ClientsEdit: 'clients-edit',
  Cars: 'cars',
  CarsList: 'cars-list',
  CarsCreate: 'cars-create',
  CarsEdit: 'cars-edit',
  Settings: 'settings',
  Offices: 'offices',
  OfficesList: 'offices-list',
  OfficesCreate: 'offices-create',
  OfficesEdit: 'offices-edit',
  Sources: 'sources',
  SourcesList: 'sources-list',
  SourcesCreate: 'sources-create',
  SourcesEdit: 'sources-edit',
  Statuses: 'statuses',
  StatusesList: 'statuses-list',
  StatusesCreate: 'statuses-create',
  StatusesEdit: 'statuses-edit',
};

const PageTitle = {
  Leads: 'Заявки',
  LeadsList: 'Список заявок',
  LeadsCreate: 'Додати нову заявку',
  LeadsEdit: 'Редагувати заявку',
  LeadsTasks: 'Завдання',
  Clients: 'Клієнти',
  ClientsList: 'Список клієнтів',
  ClientsCreate: 'Додати клієнта',
  ClientsEdit: 'Редагувати клієнта',
  Cars: 'Авто',
  CarsList: 'Список авто',
  CarsCreate: 'Додати авто',
  CarsEdit: 'Редагувати авто',
  Settings: 'Налаштування',
  Offices: 'Офіси',
  OfficesList: 'Список офісів',
  OfficesCreate: 'Додати офіс',
  OfficesEdit: 'Редагувати офіс',
  Sources: 'Джерела трафіку',
  SourcesList: 'Список джерел трафіку',
  SourcesCreate: 'Додати джерело трафіку',
  SourcesEdit: 'Редагувати джерело трафіку',
  Statuses: 'Статуси',
  StatusesList: 'Список статусів',
  StatusesCreate: 'Додати статус',
  StatusesEdit: 'Редагувати статус',
};

const navItems = [
  {
    key: NavItem.Root,
    icon: <Logo />,
    label: (
      <Link to={AppRoute.Root}>Magnum Cars CRM</Link>
    ),
    title: null,
  },
  {
    type: 'divider',
  },
  {
    key: NavItem.Leads,
    label: PageTitle.Leads,
    icon: <RocketOutlined />,
    children: [
      {
        key: NavItem.LeadsList,
        label: <Link to={AppRoute.Leads}>{PageTitle.LeadsList}</Link>
      },
      {
        key: NavItem.LeadsCreate,
        label: <Link to={`${AppRoute.Leads}/create`}>{PageTitle.LeadsCreate}</Link>,
      },
      {
        key: NavItem.LeadsTasks,
        label: <Link to={`${AppRoute.Leads}/tasks`}>{PageTitle.LeadsTasks}</Link>,
      },
    ],
  },
  {
    key: NavItem.Clients,
    label: 'Клієнти',
    icon: <TeamOutlined />,
    children: [
      {
        key: NavItem.ClientsList,
        label: <Link to={AppRoute.Clients}>{PageTitle.ClientsList}</Link>
      },
      {
        key: NavItem.ClientsCreate,
        label: <Link to={`${AppRoute.Clients}/create`}>{PageTitle.ClientsCreate}</Link>
      },
    ],
  },
  {
    key: NavItem.Cars,
    label: PageTitle.Cars,
    icon: <CarOutlined />,
    children: [
      {
        key: NavItem.CarsList,
        label: <Link to={AppRoute.Cars}>{PageTitle.CarsList}</Link>
      },
      {
        key: NavItem.CarsCreate,
        label: <Link to={`${AppRoute.Cars}/create`}>{PageTitle.CarsCreate}</Link>
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: NavItem.Settings,
    label: PageTitle.Settings,
    icon: <SettingOutlined />,
    children: [
      {
        key: NavItem.Offices,
        label: PageTitle.Offices,
        icon: <HomeOutlined />,
        children: [
          {
            key: NavItem.OfficesList,
            label: <Link to={AppRoute.Offices}>{PageTitle.Offices}</Link>,
          },
          {
            key: NavItem.OfficesCreate,
            label: <Link to={`${AppRoute.Offices}/create`}>{PageTitle.OfficesCreate}</Link>
          },
        ],
      },
      {
        key: NavItem.Sources,
        label: PageTitle.Sources,
        icon: <AreaChartOutlined />,
        children: [
          {
            key: NavItem.SourcesList,
            label: <Link to={AppRoute.Sources}>{PageTitle.SourcesList}</Link>
          },
          {
            key: NavItem.SourcesCreate,
            label: <Link to={`${AppRoute.Sources}/create`}>{PageTitle.SourcesCreate}</Link>
          },
        ],
      },
      {
        key: NavItem.Statuses,
        label: PageTitle.Statuses,
        icon: <HourglassOutlined />,
        children: [
          {
            key: NavItem.StatusesList,
            label: <Link to={AppRoute.Statuses}>{PageTitle.StatusesList}</Link>
          },
          {
            key: NavItem.StatusesCreate,
            label: <Link to={`${AppRoute.Statuses}/create`}>{PageTitle.StatusesCreate}</Link>
          },
        ],
      },
    ],
  },
];

const pathnameToNavItem = {
  [AppRoute.Leads]: [NavItem.Leads, NavItem.LeadsList],
  [`${AppRoute.Leads}/create`]: [NavItem.Leads, NavItem.LeadsCreate],
  [AppRoute.Clients]: [NavItem.Clients, NavItem.ClientsList],
  [`${AppRoute.Clients}/create`]: [NavItem.Clients, NavItem.ClientsCreate],
  [AppRoute.Cars]: [NavItem.Cars, NavItem.CarsList],
  [`${AppRoute.Cars}/create`]: [NavItem.Cars, NavItem.CarsCreate],
  [AppRoute.Settings]: [NavItem.Settings],
  [AppRoute.Offices]: [NavItem.Settings, NavItem.Offices, NavItem.OfficesList],
  [`${AppRoute.Offices}/create`]: [NavItem.Settings, NavItem.Offices, NavItem.OfficesCreate],
  [AppRoute.Sources]: [NavItem.Settings, NavItem.Sources, NavItem.SourcesList],
  [`${AppRoute.Sources}/create`]: [NavItem.Settings, NavItem.Sources, NavItem.SourcesCreate],
  [AppRoute.Statuses]: [NavItem.Settings, NavItem.Statuses, NavItem.StatusesList],
  [`${AppRoute.Statuses}/create`]: [NavItem.Settings, NavItem.Statuses, NavItem.StatusesCreate],
};

const getOpenedKeys = (pathname) => pathnameToNavItem[pathname];
const getSelectedKeys = (pathname) => pathnameToNavItem[pathname];

const Sidenav = () => {
  const { pathname } = useLocation();
  return (
    <Menu
      theme="light"
      mode="inline"
      items={navItems}
      defaultOpenKeys={getOpenedKeys(pathname)}
      defaultSelectedKeys={getSelectedKeys(pathname)}
      style={{
        height: '100%',
        maxHeight: '100vh',
        overflow: 'auto',
      }}
    />
  );
};

export default Sidenav;
