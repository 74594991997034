import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ClientForm } from '../../components/ClientForm/ClientForm';
import { AppRoute } from '../../const';
import { fetchOneClient, updateClient } from '../../slices/actions/clients.actions';

export const ClientsEdit = () => {
  const { loading } = useSelector((state) => state.form);
  const currentClient = useSelector((state) => state.clients.currentEntity);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { message } = App.useApp();

  const handleFormSubmit = async (values) => {
    await dispatch(updateClient({ id, data: values }))
      .unwrap()
      .then(() => {
        message.success('Клієнт успішно оновлений!');
      });
  };

  useEffect(() => {
    dispatch(fetchOneClient(id));
  }, [dispatch, id]);

  return (
    <div>
      <Helmet>
        <title>Редагувати клієнта</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Clients}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <ClientForm
              title="Редагувати клієнта"
              initialValues={currentClient}
              loading={loading}
              onFinish={handleFormSubmit}
            />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
