import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Form, Input, Select, Typography } from 'antd';
import { EditOutlined, PhoneFilled, UserAddOutlined } from '@ant-design/icons';
import SocialIcon from '../../../../components/Icons/SocialIcon';
import { fetchClients, fetchOneClient } from '../../../../slices/actions/clients.actions';
import { ClientModal } from '../ClientModal/ClientModal';

const { Text } = Typography;

const connectionSelecttOptions = [
  {
    value: 'phone',
    label: 'Телефон',
  },
  {
    value: 'viber',
    label: 'Viber',
  },
  {
    value: 'telegram',
    label: 'Telegram',
  },
  {
    value: 'signal',
    label: 'Signal',
  },
];

const renderConnectionTypeItem = ({ label, value }) => {
  let icon = null;

  switch (value) {
    case 'phone':
    default:
      icon = <PhoneFilled/>;
      break;
    case 'viber':
      icon = <SocialIcon.Viber />;
      break;
    case 'telegram':
      icon = <SocialIcon.Telegram />;
      break;
    case 'signal':
      icon = <SocialIcon.Signal />;
      break;
  }
  return (
    <Flex gap={4}>{icon}{label}</Flex>
  );
};

export const ClientForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { lead, client } = useSelector((state) => state.lead);
  const clients = useSelector((state) => state.clients.entities);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const clientsSelectOptions = clients?.map(({ id: value, fullName: label }) => ({ value, label }));

  const handleClientSelectChange = (id) => {
    dispatch(fetchOneClient(id));
  };

  const handleEditClientButtonClick = () => {
    setIsModalVisible(true);
    setIsEditModal(true);
  };

  const handleNewClientButtonClick = () => {
    setIsModalVisible(true);
    setIsEditModal(false);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (lead) {
      dispatch(fetchOneClient(lead.client.id));
      form.setFieldsValue({
        connectionType: lead.connectionType,
        clientComment: lead.client?.comment,
      });
    }
  }, [dispatch, form, lead]);

  useEffect(() => {
    if (client) {
      form.setFieldsValue({
        clientId: client.id,
        clientPhone: client.phone,
        ipnCode: client.ipnCode,
        passCode: client.passCode,
      });
    }
  }, [form, client]);

  useEffect(() => {
    dispatch(fetchClients({ perPage: -1 }));
  }, [dispatch, client]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="clientId"
          label={<Text strong>Вибір клієнта</Text>}
          style={{ marginBottom: 8 }}
        >
          <Select
            showSearch
            options={clientsSelectOptions}
            placeholder="Обрати клієнта"
            onChange={handleClientSelectChange}
          />
        </Form.Item>
        <Flex gap={8} style={{ marginBottom: 16 }}>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={handleEditClientButtonClick}
            disabled={!client}
          >
        Редагувати клієнта
          </Button>
          <Button
            type="text"
            onClick={handleNewClientButtonClick}
            icon={<UserAddOutlined />}
          >
        Створити клієнта
          </Button>
        </Flex>
        {
          client &&
          <>
            <Flex gap={8}>
              <Form.Item
                label={<Text strong>Номер телефону</Text>}
                name="clientPhone"
                style={{ flexGrow: 1 }}
              >
                <Input placeholder="Введіть номер телефону" />
              </Form.Item>
              <Form.Item
                label={<Text strong>Тип зв&rsquo;язку</Text>}
                name="connectionType"
                style={{ width: 150 }}
              >
                <Select
                  options={connectionSelecttOptions}
                  placeholder="Обрати тип зв'язку"
                  labelRender={renderConnectionTypeItem}
                  optionRender={renderConnectionTypeItem}
                />
              </Form.Item>
            </Flex>
            <Flex gap={8}>
              <Form.Item
                name="ipnCode"
                label={<Text strong>Ідентифікаційний код</Text>}
                style={{ flexGrow: 1 }}
              >
                <Input placeholder="Введіть ідентифікаційний код" />
              </Form.Item>
              <Form.Item
                name="passCode"
                label={<Text strong>Паспорт</Text>}
                style={{ flexGrow: 1 }}
              >
                <Input placeholder="Введіть паспорт" />
              </Form.Item>
            </Flex>
          </>
        }
        <Form.Item
          name="offerType"
          label={<Text strong>Тип послуги</Text>}
        >
          <Select
            options={[]}
            placeholder="Обрати тип послуги"
            style={{ width: '100%' }}
            disabled
          />
        </Form.Item>
        <Form.Item
          name="clientComment"
          label={<Text strong>Коментар (клієнт)</Text>}
        >
          <Input.TextArea
            rows={4}
            placeholder="Введіть коментар"
          />
        </Form.Item>
      </Form>
      {
        isEditModal ?
          <ClientModal
            visible={isModalVisible}
            client={client}
            onClose={handleModalClose}
          /> :
          <ClientModal
            visible={isModalVisible}
            onClose={handleModalClose}
          />
      }
    </>
  );
};
