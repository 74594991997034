import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  errors: {},
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.loading = true;
        state.errors = {};
      }
    );
    builder.addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state) => {
        state.loading = false;
        state.errors = {};
      }
    );
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      }
    );
  },
});

export const { clearErrors } = formSlice.actions;

export default formSlice.reducer;
