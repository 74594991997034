import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertFetchParamsForBackend, EntitiesAdapter } from '../../adapter.js';

const ENDPOINT = '/api/leads';

export const fetchLeads = createAsyncThunk(
  'leads/fetch',
  async (params = {}, { extra }) => {
    const { api } = extra;
    const response = await api.get(ENDPOINT, {
      params: convertFetchParamsForBackend(params),
    });
    return response.data;
  },
);

export const fetchOneLead = createAsyncThunk(
  'leads/fetch/one',
  async (id, { extra }) => {
    const { api } = extra;
    const response = await api.get(`${ENDPOINT}/${id}`);
    const { data } = response.data;
    return data;
  },
);

export const createLead = createAsyncThunk(
  'leads/create',
  async (data = {}, { extra }) => {
    const { api } = extra;
    const response = await api.post(ENDPOINT, EntitiesAdapter.leadToBackend(data));
    return response.data;
  },
);

export const updateLead = createAsyncThunk(
  'leads/update',
  async ({ id, data = {} }, { extra }) => {
    const { api } = extra;
    const updates = EntitiesAdapter.leadToBackend(data);
    const response = await api.patch(`${ENDPOINT}/${id}`, updates);
    return response.data;
  },
);

export const deleteLead = createAsyncThunk(
  'leads/delete',
  async (id, { getState, dispatch, extra }) => {
    const { api } = extra;
    const response = await api.delete(`${ENDPOINT}/${id}`);
    const state = getState();
    dispatch(fetchLeads(state.offices.ui));
    return response.data;
  },
);
