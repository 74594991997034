import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { SourceForm } from '../../components/SourceForm/SourceForm';
import { AppRoute } from '../../const';
import { fetchOneSource, updateSource } from '../../slices/actions/sources.actions';
import { useEffect } from 'react';

export const SourcesEdit = () => {
  const { loading } = useSelector((state) => state.form);
  const currentSource = useSelector((state) => state.sources.currentEntity);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { message } = App.useApp();

  const handleFormSubmit = async (values) => {
    await dispatch(updateSource({ id, data: values }))
      .unwrap()
      .then(() => {
        message.success('Джерело трафіку успішно оновлене!');
      });
  };

  useEffect(() => {
    dispatch(fetchOneSource(id));
  }, [dispatch, id]);

  return (
    <div>
      <Helmet>
        <title>Редагувати джерело трафіку</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Sources}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <SourceForm
              title="Редагувати джерело трафіку"
              initialValues={currentSource}
              loading={loading}
              onFinish={handleFormSubmit}
            />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
