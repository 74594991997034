import PropTypes from 'prop-types';
import { Button, Card, Flex, Form, Input, Typography } from 'antd';
import { CloseOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DataKey, DataLabel } from '../../const';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { clearErrors } from '../../slices/slices/form.slice';

const { Text } = Typography;

export const ClientForm = ({
  title,
  initialValues = {},
  loading = false,
  onFinish,
}) => {
  const { errors } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const getValidationStatus = (field) => errors[field] ? 'error' : 'success';
  const getValidationErrors = (field) => errors[field]?.join('\n');

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => () => dispatch(clearErrors()), [dispatch]);

  return (
    <Card title={title}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={<Text strong>{DataLabel.LastName}</Text>}
          name={DataKey.LastName}
          validateStatus={getValidationStatus(DataKey.LastName)}
          help={getValidationErrors(DataKey.LastName)}
        >
          {/* suffix={loading && <LoadingOutlined />} */}
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.FirstName}</Text>}
          name={DataKey.FirstName}
          validateStatus={getValidationStatus(DataKey.FirstName)}
          help={getValidationErrors(DataKey.FirstName)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.MiddleName}</Text>}
          name={DataKey.MiddleName}
          validateStatus={getValidationStatus(DataKey.MiddleName)}
          help={getValidationErrors(DataKey.MiddleName)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.Email}</Text>}
          name={DataKey.Email}
          validateStatus={getValidationStatus(DataKey.Email)}
          help={getValidationErrors(DataKey.Email)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.Phone}</Text>}
          name={DataKey.Phone}
          validateStatus={getValidationStatus(DataKey.Phone)}
          help={getValidationErrors(DataKey.Phone)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.IpnCode}</Text>}
          name={DataKey.IpnCode}
          validateStatus={getValidationStatus(DataKey.IpnCode)}
          help={getValidationErrors(DataKey.IpnCode)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.PassCode}</Text>}
          name={DataKey.PassCode}
          validateStatus={getValidationStatus(DataKey.PassCode)}
          help={getValidationErrors(DataKey.PassCode)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Form.Item
            label={<Text strong>{DataLabel.Comment}</Text>}
            name={DataKey.Comment}
            validateStatus={getValidationStatus(DataKey.Comment)}
            help={getValidationErrors(DataKey.Comment)}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
          {
            loading &&
            <LoadingOutlined
              style={{ position: 'absolute', top: 40, right: 11, color: 'rgba(0, 0, 0, 0.25)' }}
            />
          }
        </div>
        <Form.Item
          label={<Text strong>Додаткова інформація</Text>}
        >
          <Form.List name={DataKey.AdditionalFields}>
            {(fields, operations) => (
              <Flex vertical>
                {fields.map((field) => (
                  <Flex
                    key={field.key}
                    gap={16}
                    align="center"
                  >
                    <Form.Item
                      key={field.key}
                      name={[field.name, 'name']}
                      label="Назва"
                      validateStatus={getValidationStatus(`additional_fields.${field.key}.name`)}
                      help={getValidationErrors(`additional_fields.${field.key}.name`)}
                    >
                      <Input placeholder="Поле" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'value']}
                      label="Значення"
                      validateStatus={getValidationStatus(`additional_fields.${field.key}.value`)}
                      help={getValidationErrors(`additional_fields.${field.key}.value`)}
                      style={{ flexGrow: 1 }}
                    >
                      <Input placeholder="Поле" disabled={loading} />
                    </Form.Item>
                    <CloseOutlined onClick={() => operations.remove(field.name)} disabled={loading} />
                  </Flex>
                ))}
                <Button
                  type="dashed"
                  icon={<PlusCircleOutlined />}
                  onClick={() => operations.add()}
                  disabled={loading}
                >Додати поле
                </Button>
              </Flex>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Відправити</Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

ClientForm.propTypes = {
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
};
