import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { StatusForm } from '../../components/StatusForm/StatusForm';
import { AppRoute } from '../../const';
import { fetchOneStatus, updateStatus } from '../../slices/actions/statuses.actions';
import { useEffect } from 'react';

export const StatusesEdit = () => {
  const { loading } = useSelector((state) => state.form);
  const currentStatus = useSelector((state) => state.statuses.currentEntity);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { message } = App.useApp();

  const handleFormSubmit = async (values) => {
    await dispatch(updateStatus({ id, data: values }))
      .unwrap()
      .then(() => {
        message.success('Статус успішно оновлений!');
      });
  };

  useEffect(() => {
    dispatch(fetchOneStatus(id));
  }, [dispatch, id]);

  return (
    <div>
      <Helmet>
        <title>Редагувати статус</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Statuses}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <StatusForm
              title="Редагувати статус"
              initialValues={currentStatus}
              loading={loading}
              onFinish={handleFormSubmit}
            />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
