import Icon from '@ant-design/icons';

const SvgComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 240 280"
    width="1em"
    height="1em"
  >
    <path
      fill="#E6A60E"
      d="M239 1c.667.331 1.784.544 1.909 1.018.287 1.089.091 2.305.091 3.47 0 73.858 0 147.716-.229 221.701-29.373 22.57-61.822 37.112-98.794 42.433.12-2.139-.11-3.256.275-4.087 3.558-7.679 7.125-15.358 10.952-22.903.624-1.228 2.428-2.105 3.873-2.629 8.528-3.093 17.307-5.578 25.622-9.152 8.329-3.58 16.404-7.872 24.225-12.473 5.148-3.03 4.904-4.888 1.553-9.477-6.626-9.076-13.232-18.183-19.356-27.596-1.195-1.837-.97-5.49.013-7.667 2.127-4.713 5.584-8.825 7.718-13.537 2.108-4.655 3.923-3.19 6.209-.542 4.355 5.046 8.684 10.113 13.107 15.268v-68.974c-5.001 7.15-9.08 14.344-12.978 21.636-10.13 18.95-20.185 37.94-30.258 56.92-12.064 22.733-24.084 45.488-36.192 68.197-3.446 6.464-7.215 12.757-10.666 19.22-1.571 2.943-2.729 6.109-4.074 9.174-.444 0-.889 0-1.674-.37-4.339-7.151-8.557-13.812-12.296-20.732-15.408-28.516-30.7-57.095-45.976-85.681-9.058-16.952-17.998-33.968-27.014-50.943-2.999-5.644-6.07-11.25-9.652-17.879v69.461c4.212-4.475 8.155-8.462 11.857-12.663 2.818-3.198 5.58-3.653 8.104-.08 3.371 4.77 6.503 9.72 9.473 14.75.455.77-.172 2.631-.87 3.556-3.744 4.96-7.725 9.74-11.514 14.666-4.622 6.008-9.135 12.099-13.703 18.164 3.665 2.931 6.822 5.52 10.046 8.024 12.982 10.083 26.883 18.252 43.475 20.817 3.78.585 6.547 2.27 7.884 6.267a134.306 134.306 0 0 0 5.98 14.863c1.822 3.845.346 4.826-3.18 4.78-1.665-.023-3.407.274-4.985-.108-20.943-5.077-41.025-12.473-59.903-22.923-8.48-4.695-16.627-9.914-23.036-17.417C3.866 226.206 2.348 225.176 1 224 1 151.163.994 78.325 1.042 5.488c0-1.496.625-2.992 1.115-4.245 8.72 16.097 17.397 31.89 25.81 47.823 7.34 13.899 14.295 28 21.578 41.928 7.44 14.227 15.081 28.347 22.613 42.525 6.008 11.31 12.032 22.61 17.962 33.961 6.086 11.65 11.975 23.403 18.126 35.018 3.956 7.47 8.228 14.773 12.65 22.67 7.31-13.614 14.42-26.768 21.448-39.965 12.171-22.856 24.34-45.713 36.419-68.616 11.016-20.89 21.887-41.855 32.88-62.757 3.482-6.623 7.28-13.08 10.703-19.733C227.996 23.116 233.46 12.037 239 1z"
    />
    <path
      fill="#E6A60E"
      d="M74.241 109.25c7.545-9.882 14.8-19.526 22.135-29.107 7.149-9.335 14.523-18.502 21.5-27.962 2.54-3.443 4.545-2.84 6.59-.11 15.915 21.261 31.755 42.58 47.752 64.06l-13.965 23.119-37.69-45.553c-7.35 8.9-14.454 17.467-21.518 26.065-3.84 4.675-7.434 9.567-11.472 14.061-3.738 4.16-6.052 3.627-8.671-1.157-1.95-3.563-3.822-7.254-6.334-10.405-3.953-4.959-2.627-8.899 1.673-13.011z"
    />
  </svg>
);

export const Logo = (props) => <Icon component={SvgComponent} {...props} />;
