import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { OfficeForm } from '../../components/OfficeForm/OfficeForm';
import { AppRoute } from '../../const';
import { fetchOneOffice, updateOffice } from '../../slices/actions/offices.actions';
import { useEffect } from 'react';

export const OfficesEdit = () => {
  const { loading } = useSelector((state) => state.form);
  const currentOffice = useSelector((state) => state.offices.currentEntity);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { message } = App.useApp();

  const handleFormSubmit = async (values) => {
    await dispatch(updateOffice({ id, data: values }))
      .unwrap()
      .then(() => {
        message.success('Офіс успішно оновлено!');
      });
  };

  useEffect(() => {
    dispatch(fetchOneOffice(id));
  }, [dispatch, id]);

  return (
    <div>
      <Helmet>
        <title>Редагувати офіс</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Offices}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <OfficeForm
              title="Редагувати офіс"
              initialValues={currentOffice}
              loading={loading}
              onFinish={handleFormSubmit}
            />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
