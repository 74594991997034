import { configureStore } from '@reduxjs/toolkit';
import { createApi } from '../api';
import history from '../history';

import userSlice from './slices/user.slice.js';
import clientsSlice from './slices/clients.slice.js';
import leadsSlice from './slices/leads.slice.js';
import officesSlice from './slices/offices.slice.js';
import sourcesSlice from './slices/sources.slice.js';
import statusesSlice from './slices/statuses.slice.js';
import carsSlice from './slices/cars.slice.js';
import formSlice from './slices/form.slice.js';
import { fetchLeads } from './actions/leads.actions.js';
import { fetchClients } from './actions/clients.actions.js';
import { fetchOffices } from './actions/offices.actions.js';
import { fetchSources } from './actions/sources.actions.js';
import { fetchStatuses } from './actions/statuses.actions.js';
import { fetchCars } from './actions/cars.actions.js';
import { fetchUserStatus } from './actions/user.actions.js';
import leadSlice from './slices/lead.slice.js';

const api = createApi();

const store = configureStore({
  reducer: {
    user: userSlice,
    clients: clientsSlice,
    leads: leadsSlice,
    offices: officesSlice,
    sources: sourcesSlice,
    statuses: statusesSlice,
    cars: carsSlice,
    form: formSlice,
    lead: leadSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: { api, history },
    },
  }),
});

store.dispatch(fetchUserStatus());
store.dispatch(fetchClients());
store.dispatch(fetchLeads());
store.dispatch(fetchOffices());
store.dispatch(fetchSources());
store.dispatch(fetchStatuses());
store.dispatch(fetchCars());

export default store;
