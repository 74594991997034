import { createSlice } from '@reduxjs/toolkit';
import { fetchUserStatus, loginUser, logoutUser } from '../actions/user.actions';
import { AuthorizationStatus } from '../../const';

const initialState = {
  user: '',
  authorizationStatus: AuthorizationStatus.NoAuth,
  error: null,
};

const PENDING_ACTIONS = [
  fetchUserStatus.pending.toString(),
  loginUser.pending.toString(),
];

const FULFILLED_ACTIONS = [
  fetchUserStatus.fulfilled.toString(),
  loginUser.fulfilled.toString(),
];

const REJECTED_ACTIONS = [
  fetchUserStatus.rejected.toString(),
  loginUser.rejected.toString(),
];

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStatus.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
        state.error = null;
      });

    builder.addMatcher(
      (action) => PENDING_ACTIONS.includes(action.type),
      (state) => {
        state.user = '';
        state.authorizationStatus = AuthorizationStatus.Unknown;
        state.error = null;
      }
    );

    builder.addMatcher(
      (action) => FULFILLED_ACTIONS.includes(action.type),
      (state) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
        state.error = null;
      }
    );

    builder.addMatcher(
      (action) => REJECTED_ACTIONS.includes(action.type),
      (state, action) => {
        state.user = '';
        state.authorizationStatus = AuthorizationStatus.NoAuth;
        state.error = action.payload;
      }
    );
  },
});

export default userSlice.reducer;
