import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { SourceForm } from '../../components/SourceForm/SourceForm';
import { AppRoute } from '../../const';
import { createSource } from '../../slices/actions/sources.actions';

export const SourcesCreate = () => {
  const { modal } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    await dispatch(createSource(values)).unwrap().then(() => {
      modal.success({
        title: 'Успіх',
        content: 'Джерело трафіку успішно створено!',
        onOk: () => navigate(AppRoute.Sources),
      });
    });
  };

  return (
    <div>
      <Helmet>
        <title>Додати нове джерело трафіку</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Sources}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <SourceForm title="Створити джерело трафіку" onFinish={handleFormSubmit} />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
