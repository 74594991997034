import PropTypes from 'prop-types';
import { Table as AntdTable, Button, Card, Flex, Input, Tooltip } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsFilter } from '../ColumnsFilter/ColumnsFilter';
import { Link } from 'react-router-dom';

const sortDirections = ['ascend', 'descend', 'ascend'];

export const Table = (props) => {
  const {
    title,
    route,
    searchHelp,
    columns,
    dataSource,
    rowKey,
    total,
    current,
    pageSize,
    isLoading,
    onSearchChange,
    onSwitchChange,
    onResetChange,
    onTableChange,
    onTableRow,
  } = props;


  const pagination = { total, current, pageSize, showSizeChanger: true };

  return (
    <Card
      title={title}
      extra={
        <Flex align="center" gap={24}>
          <Tooltip title={searchHelp}>
            <Input
              placeholder="Пошук..."
              onChange={onSearchChange}
              prefix={<SearchOutlined />}
            />
          </Tooltip>
          <ColumnsFilter
            columns={columns}
            onSwitchChange={onSwitchChange}
            onResetChange={onResetChange}
          />
          <Link to={`${route}/create`}>
            <Button type="primary" shape="round" icon={<PlusOutlined />}>
                            Додати
            </Button>
          </Link>
        </Flex>
      }
    >
      <AntdTable
        columns={columns}
        sortDirections={sortDirections}
        dataSource={dataSource}
        rowKey={rowKey}
        pagination={pagination}
        loading={isLoading}
        onChange={onTableChange}
        onRow={onTableRow}
      />
    </Card>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  searchHelp: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  dataSource: PropTypes.arrayOf(PropTypes.object),
  rowKey: PropTypes.string,
  total: PropTypes.number,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  isLoading: PropTypes.bool,
  onSearchChange: PropTypes.func,
  onSwitchChange: PropTypes.func,
  onResetChange: PropTypes.func,
  onTableChange: PropTypes.func,
  onTableRow: PropTypes.func,
};
