import React from 'react';
import { Flex, Select } from 'antd';

import type { SelectProps } from 'antd';
import type { Status } from '../../types/types';

type StatusSelectProps = {
  currentStatus: Status;
  statuses: Status[];
  onChange: () => void;
};

export const StatusSelect = (props: StatusSelectProps): React.JSX.Element => {
  const { currentStatus, statuses, onChange } = props;
  const options: SelectProps['options'] = statuses.map(({ id, title, color }) => ({ value: id, label: title, color }));

  return (
    <Select
      options={options}
      defaultValue={currentStatus.id}
      style={{ width: 200 }}
      onChange={onChange}
      labelRender={({ label, value }) => {
        const color = statuses.find((status) => status.id === value)?.color;
        return (
          <Flex gap={8} align="center">
            <span style={{
              width: 16,
              height: 16,
              backgroundColor: color,
              border: '1px solid rgba(0, 0, 0, 0.06)',
              borderRadius: 2,
            }}
            >
            </span>
            {label}
          </Flex>
        );
      }}
      optionRender={(option) => (
        <Flex gap={8} align="center">
          <span style={{
            width: 16,
            height: 16,
            backgroundColor: option.data.color,
            border: '1px solid rgba(0, 0, 0, 0.06)',
            borderRadius: 2,
          }}
          >
          </span>
          {option.data.label}
        </Flex>
      )}
      showSearch
    />
  );
};
