import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';

export const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="На жаль, сторінка, яку ви відвідали, не існує."
    extra={(
      <Link to={AppRoute.Root}>
        <Button type="primary">На головну</Button>
      </Link>
    )}
  />);
