import React from 'react';
import { Badge, Button, Flex, Skeleton } from 'antd';
import { StatusWithCount } from '../../types/types';

type StatusesBarProps = {
  statuses: StatusWithCount[];
  selected: number[];
  onClick: (status: number) => void;
  onReset: () => void;
};

export const StatusesBar = ({
  statuses,
  selected,
  onClick,
  onReset,
}: StatusesBarProps): React.JSX.Element => {
  const handleReset = (): void => {
    onReset();
  };

  return (
    <Flex wrap="wrap" gap={8} style={{ paddingBottom: 24 }}>
      {
        statuses.length > 0 && (
          <>
            {statuses.map((status) => {
              const isChecked = selected.includes(status.id);

              const handleClick = () => {
                onClick(status.id);
              };

              return (
                <Button
                  key={status.id}
                  onClick={handleClick}
                  type="default"
                  style={{ height: 'auto', lineHeight: 1, padding: 4 }}
                >
                  <Flex align="center" gap={4}>
                    <Badge count={status.count} color={isChecked ? 'gold' : 'gray'} />
                    {status.title}
                  </Flex>
                </Button>
              );
            })}
            <Button
              onClick={handleReset}
              type='default'
              style={{ height: 'auto', lineHeight: 1, padding: 4 }}
              disabled={statuses.length === selected.length}
            >
            Вибрати всі статуси
            </Button>
          </>
        )
      }
      {!statuses.length && (
        <>
          <Skeleton.Button active style={{ width: 75 }} />
          <Skeleton.Button active style={{ width: 150 }} />
          <Skeleton.Button active style={{ width: 50 }} />
          <Skeleton.Button active style={{ width: 75 }} />
          <Skeleton.Button active style={{ width: 125 }} />
          <Skeleton.Button active style={{ width: 50 }} />
        </>
      )}
    </Flex>
  );
};
