import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './components/App/App';

import 'antd/dist/reset.css';
import './assets/styles/custom.css';

const rootElement = document.getElementById('app') as HTMLElement;
const root = createRoot(rootElement);
root.render(<App />);
