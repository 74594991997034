import PropTypes from 'prop-types';
import { Button, Card, ColorPicker, Flex, Form, Input, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DataKey, DataLabel } from '../../const';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { clearErrors } from '../../slices/slices/form.slice';

const { Text } = Typography;

export const StatusForm = ({
  title,
  initialValues = {},
  loading = false,
  onFinish,
}) => {
  const { errors } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const getValidationStatus = (field) => errors[field] ? 'error' : 'success';
  const getValidationErrors = (field) => errors[field]?.join('\n');

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => () => dispatch(clearErrors()), [dispatch]);

  return (
    <Card title={title}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Flex gap={16}>
          <Form.Item
            label={<Text strong>{DataLabel.Title}</Text>}
            name={DataKey.Title}
            validateStatus={getValidationStatus(DataKey.Title)}
            help={getValidationErrors(DataKey.Title)}
            required
            style={{ flexGrow: 1 }}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label={<Text strong>{DataLabel.Color}</Text>}
            name={DataKey.Color}
            validateStatus={getValidationStatus(DataKey.Color)}
            help={getValidationErrors(DataKey.Color)}
            getValueFromEvent={(color) => color.toHexString()}
            initialValue="#fff"
            style={{ minWidth: 150 }}
          >
            <ColorPicker
              showText
              disabled={loading}
              disabledAlpha
              format="hex"
            />
          </Form.Item>
        </Flex>
        <div style={{ position: 'relative' }}>
          <Form.Item
            label={<Text strong>{DataLabel.Comment}</Text>}
            name={DataKey.Comment}
            validateStatus={getValidationStatus(DataKey.Comment)}
            help={getValidationErrors(DataKey.Comment)}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
          {
            loading &&
            <LoadingOutlined
              style={{ position: 'absolute', top: 40, right: 11, color: 'rgba(0, 0, 0, 0.25)' }}
            />
          }
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">Відправити</Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

StatusForm.propTypes = {
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
};
