import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children, restrictedFor, redirectTo }) => {
  const { authorizationStatus } = useSelector((state) => state.user);
  return (
    authorizationStatus !== restrictedFor ? children : <Navigate to={redirectTo} />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  restrictedFor: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
};
