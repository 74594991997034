import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppRoute, Endpoint, HttpCode } from '../../const';
import { Token } from '../../token';
import { RawAuthData } from '../../types';

export const fetchUserStatus = createAsyncThunk(
  'user/fetchStatus',
  async (_, { extra, rejectWithValue }) => {
    const { api, history } = extra;
    try {
      const { data } = await api.get(Endpoint.Status);
      return data.email;
    } catch (error) {
      if (error.response.status === HttpCode.NoAuth) {
        Token.drop();
        history.push(AppRoute.Login);
      }

      return rejectWithValue(error.response.data?.error);
    }
  },
);

export const loginUser = createAsyncThunk(
  'user/login',
  async (params, { dispatch, extra, rejectWithValue }) => {
    const { api, history } = extra;
    try {
      const { data } = await api.post(Endpoint.Login, { ...params });
      const accessToken = data[RawAuthData.AccessToken];
      Token.save(accessToken);
      history.push(AppRoute.Root);
      await dispatch(fetchUserStatus());
      return data;
    } catch (error) {
      if (error.response.status === 429) {
        return rejectWithValue(error.response.data?.message);
      }
      return rejectWithValue(error.response.data?.error);
    }
  },
);

export const logoutUser = createAsyncThunk(
  'user/logout',
  async (_, { extra }) => {
    const { api, history } = extra;
    const { data } = await api.post(Endpoint.Logout);
    Token.drop();
    history.push(AppRoute.Login);
    return data;
  },
);
