import { faker } from '@faker-js/faker';
import { useSelector } from 'react-redux';
import { Button, Collapse, ColorPicker, Descriptions, Empty, Flex, Form, Input, Select, Table, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { formatDateToUA } from '../../../../utils';

const { Text } = Typography;

export const LeadTabs = ({ form }) => {
  const sources = useSelector((state) => state.sources.entities);
  const offices = useSelector((state) => state.offices.entities);
  const { lead } = useSelector((state) => state.lead);
  const cars = useSelector((state) => state.cars.entities);

  const [mockCar, setMockCar] = useState(null);

  useEffect(() => {
    if (cars.length) {
      setMockCar(cars[faker.number.int({ min: 0, max: cars.length - 1 })]);
    }
  }, [cars]);


  const sourcesOptions = sources.map(({ id: value, title: label }) => ({ value, label }));
  const officesOptions = offices.map(({ id: value, title: label }) => ({ value, label }));

  const tasksTableColumns = [
    {
      dataIndex: 'id',
      key: 'id',
      title: 'id',
    },
    {
      dataIndex: 'date',
      key: 'date',
      title: 'Дата',
    },
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Заголовок',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Статус',
    },
  ];
  const tasksTableStatuses = [
    { value: 'todo', label: 'Зробити' },
    { value: 'inProgress', label: 'В роботі' },
    { value: 'done', label: 'Виконано' },
    { value: 'failed', label: 'Невдало' },
  ];
  const tasksTableItems = [
    {
      key: 0,
      id: 0,
      date: formatDateToUA('10/04/2024'),
      title: 'Bespoke Soft Soap',
      status: (
        <Select
          options={tasksTableStatuses}
          placeholder="Обрати статус"
          style={{ width: 200 }}
        />
      ),
    },
  ];

  const tabs = [
    {
      key: 'manager',
      label: 'Менеджер',
      children: (
        <>
          <Flex gap={16}>
            <Form.Item
              label={<Text strong>Менеджер</Text>}
              style={{ flexGrow: 1 }}
            >
              <Select
                value={lead?.manager?.name}
                options={[]}
                placeholder="Обрати менеджера"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
            <Form.Item
              label={<Text strong>Статус менеджера</Text>}
              style={{ flexGrow: 1 }}
            >
              <Select
                value={lead?.manager?.role.name}
                options={[]}
                placeholder="Обрати статус менеджера"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Flex>
          <Form.Item
            label={<Text strong>Коментар</Text>}
          >
            <Input.TextArea
              rows={4}
              value={lead?.comment}
              disabled
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              disabled
            >
            Внести аванс
            </Button>
          </Form.Item>
          <Form.Item>
            <Collapse
              defaultActiveKey={['tasks']}
              items={[
                {
                  key: 'tasks',
                  label: <Text strong>Завдання</Text>,
                  children: (
                    <Table
                      columns={tasksTableColumns}
                      dataSource={tasksTableItems}
                      pagination={false}
                    />
                  ),
                },
                {
                  key: 'extra',
                  label: <Text strong>Договори та рахунки</Text>,
                  children: (
                    <Empty />
                  ),
                },
              ]}
            />
          </Form.Item>
        </>
      ),
    },
    {
      key: 'find',
      label: 'Підбір авто',
      children: (
        <>
          <Form.Item
            label={<Text strong>Менеджер по підбору</Text>}
            style={{ width: '50%', paddingRight: 32 }}
          >
            <Select
              value={lead?.manager?.name}
              options={[]}
              placeholder="Обрати менеджера по підбору"
              style={{ width: '100%' }}
              disabled
            />
          </Form.Item>
          <Form.Item
            label={<Text strong>Коментар</Text>}
          >
            <Input.TextArea
              rows={4}
              value={lead?.comment}
              disabled
            />
          </Form.Item>
          <Form.Item>
            <Collapse
              items={[
                {
                  key: '0',
                  label: 'Калькулятор',
                  children: (
                    <Empty />
                  ),
                },
                {
                  key: '1',
                  label: 'Додати авто',
                  children: (
                    <Empty />
                  ),
                },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              disabled
            >
            Пошук серед авто
            </Button>
          </Form.Item>
        </>
      ),
    },
    {
      key: 'payments',
      label: 'Оплата декларант',
      children: (
        <>
          <Form.Item
            label={<Text strong>Основна інформація про вибране авто</Text>}
          >
            <Descriptions
              bordered
              column={1}
              items={[
                {
                  key: 'brand',
                  label: 'Бренд',
                  children: mockCar?.brand,
                },
                {
                  key: 'year',
                  label: 'Рік',
                  children: mockCar?.year,
                },
                {
                  key: 'vin',
                  label: 'VIN-код',
                  children: mockCar?.vin,
                },
                {
                  key: 'engine',
                  label: 'Двигун',
                  children: mockCar?.engine,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={<Text strong>Деталі про платежі</Text>}
          >
            <Empty />
          </Form.Item>
        </>
      ),
    },
    {
      key: 'info',
      label: 'Авто інфо',
      children: (
        <>
          <Flex gap={16}>
            <Form.Item
              name="source_id"
              label={<Text strong>Джерело трафіку</Text>}
              style={{ flexGrow: 1 }}
            >
              <Select
                options={sourcesOptions}
                placeholder="Обрати джерело трафіку"
                style={{ width: '100%' }}
                onChange={(value) => form.setFieldValue('source_id', value)}
              />
            </Form.Item>
            <Form.Item
              name="office_id"
              label={<Text strong>Офіс</Text>}
              style={{ flexGrow: 1 }}
            >
              <Select
                options={officesOptions}
                placeholder="Обрати офіс"
                style={{ width: '100%' }}
                onChange={(value) => form.setFieldValue('office_id', value)}
              />
            </Form.Item>
          </Flex>
          <Form.Item
            label={<Text strong>Основна інформація про вибране авто</Text>}
          >
            <Descriptions
              bordered
              column={1}
              items={[
                {
                  key: 'brand',
                  label: 'Бренд',
                  children: mockCar?.brand,
                },
                {
                  key: 'model',
                  label: 'Модель',
                  children: mockCar?.model,
                },
                {
                  key: 'year',
                  label: 'Рік',
                  children: mockCar?.year,
                },
                {
                  key: 'vin',
                  label: 'VIN-код',
                  children: mockCar?.vin,
                },
                {
                  key: 'engine',
                  label: 'Двигун',
                  children: mockCar?.engine,
                },
                {
                  key: 'color',
                  label: 'Колір',
                  children: <ColorPicker defaultValue={mockCar?.color} disabled />,
                },
                {
                  key: 'price',
                  label: 'Ціна',
                  children: mockCar?.price,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={<Text strong>Дані про оплати клієнта</Text>}
          >
            <Empty />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <Tabs
      items={tabs}
      centered
    />
  );
};
