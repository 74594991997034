import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Select, Space } from 'antd';
import { LeadTabs } from '../LeadTabs/LeadTabs';

export const LeadDetails = () => {
  const [form] = Form.useForm();
  const statuses = useSelector((state) => state.statuses.entities);
  const { lead } = useSelector((state) => state.lead);
  const statusesOptions = statuses.map(({ id: value, title: label }) => ({ value, label }));

  const [statusColor, setStatusColor] = useState('#fff');

  useEffect(() => {
    if (lead) {
      form.setFieldsValue({
        'status_id': lead.status.id,
        'source_id': lead.source.id,
        'office_id': lead.office.id,
        'connection_type': lead.connectionType,
      });
      setStatusColor(lead.status.color);
    }
  }, [form, lead]);

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <Space
        gutter={16}
        direction="vertical"
        style={{ width: '100%' }}
      >
        <div style={{
          backgroundColor: statusColor,
          boxShadow: `0px -6px 0px 0px ${statusColor}`,
          borderRadius: 6,
        }}
        >
          <Form.Item name="status_id">
            <Select
              options={statusesOptions}
              placeholder="Обрати статус заявки"
              style={{
                width: '100%',
                textAlign: 'center',
              }}
              onChange={(value) => {
                form.setFieldValue('status_id', value);
                setStatusColor(() => statuses.find((status) => status.id === value)?.color);
              }}
            />
          </Form.Item>
        </div>
        <LeadTabs form={form} />
      </Space>
    </Form>
  );
};

