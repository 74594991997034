import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertFetchParamsForBackend, EntitiesAdapter } from '../../adapter.js';

const ENDPOINT = '/api/sources';

export const fetchSources = createAsyncThunk(
  'sources/fetch',
  async (params = {}, { extra }) => {
    const { api } = extra;
    const response = await api.get(ENDPOINT, {
      params: convertFetchParamsForBackend(params),
    });
    return response.data;
  },
);

export const fetchOneSource = createAsyncThunk(
  'sources/fetch/one',
  async (id, { extra }) => {
    const { api } = extra;
    const response = await api.get(`${ENDPOINT}/${id}`);
    const { data } = response.data;
    return EntitiesAdapter.sourceToFrontend(data);
  },
);

export const createSource = createAsyncThunk(
  'sources/create',
  async (data = {}, { extra, rejectWithValue }) => {
    const { api } = extra;
    try {
      const response = await api.post(ENDPOINT, EntitiesAdapter.sourceToBackend(data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const updateSource = createAsyncThunk(
  'sources/update',
  async (payload, { extra, dispatch, getState, rejectWithValue }) => {
    const { id, data } = payload;
    const { api } = extra;
    try {
      const response = await api.patch(`${ENDPOINT}/${id}`, EntitiesAdapter.sourceToBackend(data));
      const state = getState();
      dispatch(fetchSources(state.sources.ui));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  },
);

export const deleteSource = createAsyncThunk(
  'sources/delete',
  async (id, { getState, dispatch, extra }) => {
    const { api } = extra;
    const response = await api.delete(`${ENDPOINT}/${id}`);
    const state = getState();
    dispatch(fetchSources(state.sources.ui));
    return response.data;
  },
);
