import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Card, ColorPicker, DatePicker, Flex, Form, Input, InputNumber, Typography, Upload } from 'antd';
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons';
import { DataKey, DataLabel } from '../../const';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { clearErrors } from '../../slices/slices/form.slice';

const { Text } = Typography;

export const CarForm = ({
  title,
  initialValues = {},
  loading = false,
  onFinish,
}) => {
  const { errors } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  /* fix it */
  const getValidationStatus = (field) => errors?.[field] ? 'error' : 'success';
  const getValidationErrors = (field) => errors?.[field]?.join('\n');

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => () => dispatch(clearErrors()), [dispatch]);

  return (
    <Card title={title}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={<Text strong>{DataLabel.Brand}</Text>}
          name={DataKey.Brand}
          validateStatus={getValidationStatus(DataKey.Brand)}
          help={getValidationErrors(DataKey.Brand)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.Model}</Text>}
          name={DataKey.Model}
          validateStatus={getValidationStatus(DataKey.Model)}
          help={getValidationErrors(DataKey.Model)}
          required
        >
          <Input disabled={loading} />
        </Form.Item>
        <Flex gap={16}>
          <Form.Item
            label={<Text strong>{DataLabel.Year}</Text>}
            name={DataKey.Year}
            initialValue={dayjs()}
            validateStatus={getValidationStatus(DataKey.Year)}
            help={getValidationErrors(DataKey.Year)}
            required
            style={{ flexGrow: 1 }}
          >
            <DatePicker picker="year" />
          </Form.Item>
          <Form.Item
            label={<Text strong>{DataLabel.Price}</Text>}
            name={DataKey.Price}
            initialValue={0}
            validateStatus={getValidationStatus(DataKey.Price)}
            help={getValidationErrors(DataKey.Price)}
            required
            style={{ flexGrow: 1 }}
          >
            <InputNumber min={0} disabled={loading} />
          </Form.Item>
          <Form.Item
            label={<Text strong>{DataLabel.Color}</Text>}
            name={DataKey.Color}
            validateStatus={getValidationStatus(DataKey.Color)}
            help={getValidationErrors(DataKey.Color)}
            getValueFromEvent={(color) => color.toHexString()}
            initialValue="#fff"
            style={{ minWidth: 150, flexGrow: 1 }}
          >
            <ColorPicker
              showText
              disabled={loading}
              disabledAlpha
              format="hex"
            />
          </Form.Item>
        </Flex>
        <Form.Item
          label={<Text strong>{DataLabel.Vin}</Text>}
          name={DataKey.Vin}
          validateStatus={getValidationStatus(DataKey.Vin)}
          help={getValidationErrors(DataKey.Vin)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label={<Text strong>{DataLabel.Engine}</Text>}
          name={DataKey.Engine}
          validateStatus={getValidationStatus(DataKey.Engine)}
          help={getValidationErrors(DataKey.Engine)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Flex gap={16}>
          <Form.Item
            label={<Text strong>{DataLabel.Fuel}</Text>}
            name={DataKey.Fuel}
            validateStatus={getValidationStatus(DataKey.Fuel)}
            help={getValidationErrors(DataKey.Fuel)}
            style={{ flexGrow: 1 }}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label={<Text strong>{DataLabel.Location}</Text>}
            name={DataKey.Location}
            validateStatus={getValidationStatus(DataKey.Location)}
            help={getValidationErrors(DataKey.Location)}
            style={{ flexGrow: 1 }}
          >
            <Input disabled={loading} />
          </Form.Item>
        </Flex>
        <Flex gap={16}>
          <Form.Item
            label={<Text strong>{DataLabel.AuctionFee}</Text>}
            name={DataKey.AuctionFee}
            initialValue={0}
            validateStatus={getValidationStatus(DataKey.AuctionFee)}
            help={getValidationErrors(DataKey.AuctionFee)}
            style={{ flexGrow: 1 }}
          >
            <InputNumber min={0} disabled={loading} />
          </Form.Item>
          <Form.Item
            label={<Text strong>{DataLabel.BuyAt}</Text>}
            name={DataKey.BuyAt}
            initialValue={dayjs()}
            validateStatus={getValidationStatus(DataKey.BuyAt)}
            help={getValidationErrors(DataKey.BuyAt)}
            style={{ flexGrow: 1 }}
          >
            <DatePicker />
          </Form.Item>
        </Flex>
        <Form.Item
          label={<Text strong>{DataLabel.AuctionLink}</Text>}
          name={DataKey.AuctionLink}
          validateStatus={getValidationStatus(DataKey.AuctionLink)}
          help={getValidationErrors(DataKey.AuctionLink)}
        >
          <Input disabled={loading} />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Form.Item
            label={<Text strong>{DataLabel.Comment}</Text>}
            name={DataKey.Comment}
            validateStatus={getValidationStatus(DataKey.Comment)}
            help={getValidationErrors(DataKey.Comment)}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
          {
            loading &&
            <LoadingOutlined
              style={{ position: 'absolute', top: 40, right: 11, color: 'rgba(0, 0, 0, 0.25)' }}
            />
          }
        </div>
        <Form.Item
          label={<Text strong>Завантажити</Text>}
          name="dragger"
          valuePropName="fileList"
          getValueFromEvent={() => {}}
        >
          <Upload.Dragger name="files" action="/upload.do" disabled>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Натисніть або перетягніть файл для завантаження</p>
            <p className="ant-upload-hint">Підтримує завантаження як одного файла, так і кількох</p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Відправити</Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

CarForm.propTypes = {
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
};
