import { useState } from 'react';

export const useTableColumns = (getColumns) => {
  const [columns, setColumns] = useState(getColumns());

  const handleColumnsSwitch = (key) => {
    setColumns(columns.map((column) => {
      if (column.key === key) {
        column.hidden = !column.hidden;
      }
      return column;
    }));
  };

  const handleColumnsReset = () => {
    setColumns(getColumns());
  };

  return { columns, handleColumnsSwitch, handleColumnsReset };
};
