import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { App, Button, Descriptions, Flex, Typography } from 'antd';
import { Table } from '../../components/Table/Table.jsx';
import { deleteClient, fetchClients, updateClient } from '../../slices/actions/clients.actions.js';
import { DataLabel, DataKey, AppRoute, PageTitle } from '../../const';
import { formatDateToUA } from '../../utils.js';
import { useTableSearch } from '../../hooks/useTableSearch.js';
import { useTableColumns } from '../../hooks/useTableColumns.js';
import { useTableSorter } from '../../hooks/useTableSorter.js';

const { Text } = Typography;

export const ClientsIndex = () => {
  const { entities: tableItems, ui } = useSelector((state) => state.clients);
  const { total, isLoading } = ui;
  const { message, modal } = App.useApp();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { search, handleSearchChange } = useTableSearch(ui.search);
  const { orderColumn, orderDirection, currentPage, perPage, handleTableChange } = useTableSorter(ui);

  const handleRowClick = (record) => (evt) => {
    if (evt.target.tagName === 'TD') {
      const {
        lastName,
        firstName,
        middleName,
        email,
        phone,
        createdAt,
        updatedAt,
        comment,
      } = record;
      modal.info({
        title: 'Детальніше',
        content: (
          <Descriptions column={3} items={[
            {
              key: DataKey.LastName,
              label: DataLabel.LastName,
              children: lastName,
            },
            {
              key: DataKey.FirstName,
              label: DataLabel.FirstName,
              children: firstName,
            },
            {
              key: DataKey.MiddleName,
              label: DataLabel.MiddleName,
              children: middleName,
            },
            {
              key: DataKey.Email,
              label: DataLabel.Email,
              children: email,
            },
            {
              key: DataKey.Phone,
              label: DataLabel.Phone,
              children: phone,
              span: 2,
            },
            {
              key: DataKey.CreatedAt,
              label: DataLabel.CreatedAt,
              children: formatDateToUA(createdAt),
            },
            {
              key: DataKey.UpdatedAt,
              label: DataLabel.UpdatedAt,
              children: formatDateToUA(updatedAt),
              span: 2,
            },
            {
              key: DataKey.Comment,
              label: DataLabel.Comment,
              children: comment,
              span: 3,
            },
          ]}
          />),
        width: 800,
        closable: true,
        maskClosable: true,
      });
    }
  };

  const handleFieldChange = (row, name) => async (value) => {
    const { id } = row;
    const prevValue = row[name];
    try {
      if (value !== prevValue) {
        await dispatch(updateClient({ id, data: { ...row, [name]: value } })).unwrap();
        message.success('Запис успішно змінено!');
      }
    } catch (error) {
      message.error('Сталася помилка!');
    }
  };

  const handleEditClick = (id) => {
    navigate(`${AppRoute.Clients}/edit/${id}`);
  };

  const handleDeleteClick = async (id) => {
    const confirmed = await modal.confirm({
      title: 'Видалити вказаний запис?',
      okText: 'Так',
      cancelText: 'Ні',
    });

    if (confirmed) {
      await dispatch(deleteClient(id)).unwrap();
      message.success('Запис успішно видалено!');
    }
  };

  const getColumns = () => ([
    {
      key: DataKey.Id,
      dataIndex: DataKey.Id,
      title: DataLabel.Id,
      hidden: false,
      sorter: true,
      width: 75,
      render: (text) => (
        <Text ellipsis={{ tooltip: true }}>{text}</Text>
      ),
    },
    {
      key: DataKey.LastName,
      dataIndex: DataKey.LastName,
      title: DataLabel.LastName,
      hidden: false,
      sorter: true,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.LastName), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.FirstName,
      dataIndex: DataKey.FirstName,
      title: DataLabel.FirstName,
      hidden: false,
      sorter: false,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.FirstName), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.Email,
      dataIndex: DataKey.Email,
      title: DataLabel.Email,
      hidden: false,
      sorter: false,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.Email), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.Phone,
      dataIndex: DataKey.Phone,
      title: DataLabel.Phone,
      hidden: false,
      sorter: false,
      render: (text, row) => (
        <Text
          editable={{ onChange: handleFieldChange(row, DataKey.Phone), tooltip: false }}
          ellipsis={{ tooltip: true }}
        >
          {text}
        </Text>
      ),
    },
    {
      key: DataKey.CreatedAt,
      dataIndex: DataKey.CreatedAt,
      title: DataLabel.CreatedAt,
      hidden: false,
      sorter: true,
      render: (text) => (
        <Text ellipsis={{ tooltip: true }}>{formatDateToUA(text)}</Text>
      ),
    },
    {
      key: DataKey.UpdatedAt,
      dataIndex: DataKey.UpdatedAt,
      title: DataLabel.UpdatedAt,
      hidden: false,
      sorter: true,
      ellipsis: true,
      render: (text) => (
        <Text ellipsis={{ tooltip: true }}>{formatDateToUA(text)}</Text>
      ),
    },
    {
      key: DataKey.Actions,
      dataIndex: null,
      title: DataLabel.Actions,
      hidden: false,
      width: 220,
      render: ({ id, deleted }) => (
        <Flex gap={8} vertical>
          <Button type="primary" onClick={() => handleEditClick(id)}>Редагувати</Button>
          <Button type={deleted ? 'primary' : 'dashed'} onClick={() => handleDeleteClick(id)} danger>
            {deleted ? 'Примусово видалити' : 'Видалити'}
          </Button>
        </Flex>
      ),
    },
  ]);

  const { columns, handleColumnsSwitch, handleColumnsReset } = useTableColumns(getColumns);

  useEffect(() => {
    dispatch(fetchClients({
      orderColumn,
      orderDirection,
      currentPage,
      perPage,
      search,
    }));
  }, [orderColumn, orderDirection, currentPage, perPage, search]);

  return (
    <>
      <Helmet>
        <title>{PageTitle.Clients}</title>
      </Helmet>
      <Table
        title={PageTitle.Clients}
        route={AppRoute.Clients}
        searchHelp="Шукати за назвою"
        columns={columns}
        dataSource={tableItems}
        rowKey={DataKey.Id}
        total={total}
        current={currentPage}
        pageSize={perPage}
        isLoading={isLoading}
        onSearchChange={handleSearchChange}
        onSwitchChange={handleColumnsSwitch}
        onResetChange={handleColumnsReset}
        onTableChange={handleTableChange}
        onTableRow={(record) => ({
          onClick: handleRowClick(record),
        })}
      />
    </>
  );
};
