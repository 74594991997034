import { createSlice } from '@reduxjs/toolkit';
import { DataKey, SortOrderReduced } from '../../const.js';
import { fetchOneStatus, fetchStatuses } from '../actions/statuses.actions.js';
import { convertFetchMetaToFrontend, EntitiesAdapter } from '../../adapter.js';

const initialState = {
  entities: [],
  currentEntity: {},
  ids: [],
  ui: {
    [DataKey.OrderColumn]: DataKey.CreatedAt,
    [DataKey.OrderDirection]: SortOrderReduced.Descend,
    [DataKey.PerPage]: 10,
    [DataKey.CurrentPage]: 1,
    [DataKey.Search]: '',
    [DataKey.Total]: 0,
    [DataKey.IsLoading]: false,
  },
};

export const statusesSlice = createSlice({
  name: 'statuses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatuses.pending, (state) => {
        state.ui = {
          ...state.ui,
          [DataKey.IsLoading]: true,
        };
      })
      .addCase(fetchStatuses.fulfilled, (state, { payload }) => {
        const [{ data, meta }, statusesWithItemsCount] = payload;
        state.entities = data.map((entity) => ({
          ...EntitiesAdapter.statusToFrontend(entity),
          count: statusesWithItemsCount.find((status) => status.status_id === entity.id).count,
        }));
        state.ids = data.map((item) => item[DataKey.Id]);
        state.ui = {
          ...state.ui,
          ...convertFetchMetaToFrontend(meta),
          [DataKey.IsLoading]: false,
        };
      })
      .addCase(fetchOneStatus.fulfilled, (state, { payload }) => {
        state.currentEntity = payload;
      })
      .addCase(fetchStatuses.rejected, (state) => {
        state.ui = {
          ...state.ui,
          [DataKey.IsLoading]: false,
        };

      });
  },
});

export default statusesSlice.reducer;
