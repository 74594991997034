import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { App, Button, Col, Flex, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CarForm } from '../../components/CarForm/CarForm';
import { AppRoute, DataKey } from '../../const';
import { createCar } from '../../slices/actions/cars.actions';

export const CarsCreate = () => {
  const { modal } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const adaptedValues = {
      ...values,
      [DataKey.Year]: values[DataKey.Year].year(),
      [DataKey.BuyAt]: values[DataKey.BuyAt].format('YYYY-MM-DD'),
    };

    await dispatch(createCar(adaptedValues)).unwrap().then(() => {
      modal.success({
        title: 'Успіх',
        content: 'Авто успішно створено!',
        onOk: () => navigate(AppRoute.Cars),
      });
    });
  };

  return (
    <div>
      <Helmet>
        <title>Додати нове авто</title>
      </Helmet>
      <Flex vertical gap={8}>
        <Link to={AppRoute.Cars}>
          <Button type="link" icon={<ArrowLeftOutlined />}>Повернутися</Button>
        </Link>
        <Row>
          <Col span={12}>
            <CarForm title="Створити нове авто" onFinish={handleFormSubmit} />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
