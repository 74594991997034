import { DataKey, RawDataKey, normalizedKeysToRaw } from './const';
import { AdditionalField, Car, Client, Lead, Manager, Office, RawAdditionalField, RawCar, RawClient, RawLead, RawManager, RawOffice, RawRole, RawStatus, Role, Status } from './types.js';

export const convertFetchParamsForBackend = (data) => ({
  [RawDataKey.OrderColumn]: normalizedKeysToRaw[data[DataKey.OrderColumn]],
  [RawDataKey.OrderDirection]: data[DataKey.OrderDirection],
  [RawDataKey.PerPage]: data[DataKey.PerPage],
  [RawDataKey.CurrentPage]: data[DataKey.CurrentPage],
  [RawDataKey.Search]: data[DataKey.Search] ?? '',
  [RawDataKey.StatusId]: data[DataKey.StatusId] ?? [],
});

export const convertFetchMetaToFrontend = (data) => ({
  [DataKey.OrderColumn]: data[RawDataKey.OrderColumn],
  [DataKey.OrderDirection]: data[RawDataKey.OrderDirection],
  [DataKey.PerPage]: data[RawDataKey.PerPage],
  [DataKey.CurrentPage]: data[RawDataKey.CurrentPage],
  [DataKey.Total]: data[RawDataKey.Total],
});

export class EntitiesAdapter {
  static roleToFrontend(entity) {
    return {
      [Role.Id]: entity[RawRole.Id],
      [Role.Name]: entity[RawRole.Name],
      [Role.ExternalName]: entity[RawRole.ExternalName],
      [Role.CreatedAt]: entity[RawRole.CreatedAt],
      [Role.UpdatedAt]: entity[RawRole.UpdatedAt],
    };
  }

  static roleToBackend(entity) {
    return {
      [RawRole.Id]: entity[Role.Id],
      [RawRole.Name]: entity[Role.Name],
      [RawRole.ExternalName]: entity[Role.ExternalName],
      [RawRole.CreatedAt]: entity[Role.CreatedAt],
      [RawRole.UpdatedAt]: entity[Role.UpdatedAt],
    };

  }

  static carToFrontend(entity) {
    return {
      [Car.Id]: entity[RawCar.Id],
      [Car.Brand]: entity[RawCar.Brand],
      [Car.Model]: entity[RawCar.Model],
      [Car.Year]: entity[RawCar.Year],
      [Car.Vin]: entity[RawCar.Vin],
      [Car.Fuel]: entity[RawCar.Fuel],
      [Car.Location]: entity[RawCar.Location],
      [Car.Color]: entity[RawCar.Color],
      [Car.Engine]: entity[RawCar.Engine],
      [Car.Price]: entity[RawCar.Price],
      [Car.AuctionLink]: entity[RawCar.AuctionLink],
      [Car.AuctionFee]: entity[RawCar.AuctionFee],
      [Car.Comment]: entity[RawCar.Comment],
      [Car.BuyAt]: entity[RawCar.BuyAt],
      [Car.Deleted]: entity[RawCar.Deleted],
    };
  }

  static carToBackend(entity) {
    return {
      [RawCar.Brand]: entity[Car.Brand],
      [RawCar.Model]: entity[Car.Model],
      [RawCar.Year]: entity[Car.Year],
      [RawCar.Vin]: entity[Car.Vin],
      [RawCar.Fuel]: entity[Car.Fuel],
      [RawCar.Location]: entity[Car.Location],
      [RawCar.Color]: entity[Car.Color],
      [RawCar.Engine]: entity[Car.Engine],
      [RawCar.Price]: entity[Car.Price],
      [RawCar.AuctionLink]: entity[Car.AuctionLink],
      [RawCar.AuctionFee]: entity[Car.AuctionFee],
      [RawCar.Comment]: entity[Car.Comment],
      [RawCar.BuyAt]: entity[Car.BuyAt],
    };
  }

  static managerToFrontend(entity) {
    const role = EntitiesAdapter.roleToFrontend(entity[RawManager.Role]);
    return {
      [Manager.Id]: entity[RawManager.Id],
      [Manager.Name]: entity[RawManager.Name],
      [Manager.Email]: entity[RawManager.Email],
      [Manager.Role]: role,
      [Manager.Deleted]: entity[RawManager.Deleted],
    };
  }

  static managerToBackend(entity) {
    const role = EntitiesAdapter.roleToBackend(entity[Manager.Role]);
    return {
      [RawManager.Id]: entity[Manager.Id],
      [RawManager.Name]: entity[Manager.Name],
      [RawManager.Email]: entity[Manager.Email],
      [RawManager.Role]: role,
    };
  }

  static officeToFrontend(entity) {
    return {
      [Office.Id]: entity[RawOffice.Id],
      [Office.Title]: entity[RawOffice.Title],
      [Office.Comment]: entity[RawOffice.Comment],
      [Office.CreatedAt]: entity[RawOffice.CreatedAt],
      [Office.UpdatedAt]: entity[RawOffice.UpdatedAt],
      [Office.DeletedAt]: entity[RawOffice.DeletedAt],
      [Office.Deleted]: entity[RawOffice.Deleted],
    };
  }

  static officeToBackend(entity) {
    return {
      [RawOffice.Id]: entity[Office.Id],
      [RawOffice.Title]: entity[Office.Title],
      [RawOffice.Comment]: entity[Office.Comment],
      [RawOffice.CreatedAt]: entity[Office.CreatedAt],
      [RawOffice.UpdatedAt]: entity[Office.UpdatedAt],
      [RawOffice.DeletedAt]: entity[Office.DeletedAt],
    };
  }

  static statusToFrontend(entity) {
    return {
      [Status.Id]: entity[RawStatus.Id],
      [Status.Title]: entity[RawStatus.Title],
      [Status.Comment]: entity[RawStatus.Comment],
      [Status.CreatedAt]: entity[RawStatus.CreatedAt],
      [Status.UpdatedAt]: entity[RawStatus.UpdatedAt],
      [Status.DeletedAt]: entity[RawStatus.DeletedAt],
      [Status.Deleted]: entity[RawStatus.Deleted],
      [Status.Color]: entity[RawStatus.Color],
    };
  }

  static statusToBackend(entity) {
    return {
      [RawStatus.Id]: entity[Status.Id],
      [RawStatus.Title]: entity[Status.Title],
      [RawStatus.Comment]: entity[Status.Comment],
      [RawStatus.CreatedAt]: entity[Status.CreatedAt],
      [RawStatus.UpdatedAt]: entity[Status.UpdatedAt],
      [RawStatus.DeletedAt]: entity[Status.DeletedAt],
      [RawStatus.Color]: entity[Status.Color],
    };
  }

  static clientToFrontend(entity) {
    const additionalFields =
      entity[RawLead.AdditionalFields] &&
      entity[RawLead.AdditionalFields].map(EntitiesAdapter.additionalFieldToFrontend);

    return {
      [Client.Id]: entity[RawClient.Id],
      [Client.FirstName]: entity[RawClient.FirstName],
      [Client.LastName]: entity[RawClient.LastName],
      [Client.MiddleName]: entity[RawClient.MiddleName],
      [Client.FullName]: entity[RawClient.FullName],
      [Client.Email]: entity[RawClient.Email],
      [Client.Phone]: entity[RawClient.Phone],
      [Client.IpnCode]: entity[RawClient.IpnCode],
      [Client.PassCode]: entity[RawClient.PassCode],
      [Client.Code]: entity[RawClient.Code],
      [Client.PassId]: entity[RawClient.PassId],
      [Client.Comment]: entity[RawClient.Comment],
      [Client.CreatedAt]: entity[RawClient.CreatedAt],
      [Client.UpdatedAt]: entity[RawClient.UpdatedAt],
      [Client.DeletedAt]: entity[RawClient.DeletedAt],
      [Client.Deleted]: entity[RawClient.Deleted],
      [Client.AdditionalFields]: additionalFields,
    };
  }

  static clientToBackend(entity) {
    return {
      [RawClient.FirstName]: entity[Client.FirstName],
      [RawClient.LastName]: entity[Client.LastName],
      [RawClient.MiddleName]: entity[Client.MiddleName],
      [RawClient.FullName]: entity[Client.FullName],
      [RawClient.Email]: entity[Client.Email],
      [RawClient.Phone]: entity[Client.Phone],
      [RawClient.IpnCode]: entity[Client.IpnCode],
      [RawClient.PassCode]: entity[Client.PassCode],
      [RawClient.Code]: entity[Client.Code],
      [RawClient.PassId]: entity[Client.PassId],
      [RawClient.Comment]: entity[Client.Comment],
      [RawClient.AdditionalFields]: entity[Client.AdditionalFields]?.map(EntitiesAdapter.additionalFieldToBackend),
    };
  }

  static sourceToFrontend(entity) {
    return {
      [Status.Id]: entity[RawStatus.Id],
      [Status.Title]: entity[RawStatus.Title],
      [Status.Comment]: entity[RawStatus.Comment],
      [Status.CreatedAt]: entity[RawStatus.CreatedAt],
      [Status.UpdatedAt]: entity[RawStatus.UpdatedAt],
      [Status.DeletedAt]: entity[RawStatus.DeletedAt],
      [Status.Deleted]: entity[RawStatus.Deleted],
    };
  }

  static sourceToBackend(entity) {
    return {
      [RawStatus.Id]: entity[Status.Id],
      [RawStatus.Title]: entity[Status.Title],
      [RawStatus.Comment]: entity[Status.Comment],
      [RawStatus.CreatedAt]: entity[Status.CreatedAt],
      [RawStatus.UpdatedAt]: entity[Status.UpdatedAt],
      [RawStatus.DeletedAt]: entity[Status.DeletedAt],
    };
  }

  static additionalFieldToFrontend(entity) {
    if (!entity) {
      return {};
    }

    return {
      [AdditionalField.Name]: entity[RawAdditionalField.Name],
      [AdditionalField.Value]: entity[RawAdditionalField.Value],
    };
  }

  static additionalFieldToBackend(entity) {
    if (!entity) {
      return {};
    }

    return {
      [RawAdditionalField.Name]: entity[AdditionalField.Name],
      [RawAdditionalField.Value]: entity[AdditionalField.Value],
    };
  }

  static leadToFrontend(entity) {
    const manager = entity[RawLead.Manager] && EntitiesAdapter.managerToFrontend(entity[RawLead.Manager]);
    const office = entity[RawLead.Office] && EntitiesAdapter.officeToFrontend(entity[RawLead.Office]);
    const status = entity[RawLead.Status] && EntitiesAdapter.statusToFrontend(entity[RawLead.Status]);
    const client = entity[RawLead.Client] && EntitiesAdapter.clientToFrontend(entity[RawLead.Client]);
    const source = entity[RawLead.Source] && EntitiesAdapter.sourceToFrontend(entity[RawLead.Source]);
    const additionalFields = entity[RawLead.AdditionalFields] && entity[RawLead.AdditionalFields].map(EntitiesAdapter.additionalFieldToFrontend);

    return {
      [Lead.Id]: entity[RawLead.Id],
      [Lead.Manager]: manager,
      [Lead.Office]: office,
      [Lead.Status]: status,
      [Lead.Client]: client,
      [Lead.Source]: source,
      [Lead.AdditionalFields]: additionalFields,
      [Lead.Comment]: entity[RawLead.Comment],
      [Lead.Ip]: entity[RawLead.Ip],
      [Lead.CreatedAt]: entity[RawLead.CreatedAt],
      [Lead.UpdatedAt]: entity[RawLead.UpdatedAt],
      [Lead.Deleted]: entity[RawLead.Deleted],
      [Lead.ConnectionType]: entity[RawLead.ConnectionType],
    };
  }

  static leadToBackend(entity) {
    const required = {
      'manager_id': entity.manager.id,
      'status_id': entity.statusId ?? entity.status.id,
      'office_id': entity.office.id,
      'client_id': entity.client.id,
    };


    const optional = {
      'source_id': entity.sourceId ?? entity.source.id,
      'comment': entity.comment,
      'connection_type': entity.connectionType,
      'ip': entity.ip,
      'additional_fields': entity.additionalFields,
    };

    return { ...required, ...optional };
  }
}
